export const storeData = [
    {
        "name": '上海奇奕',
        "address": '上海市静安区共和新路2188号大宁久光中心东塔（A座）',
        "phone": '95065',
        "businessHours": '8:00-20:00',
        "pic": 'https://static.ledsdk.com/pro/202410/923020761500221441.png',
    },
    {
        "name": '上海倍融',
        "address": '上海市徐汇区龙华中路 596 号',
        "phone": '95065',
        "businessHours": '8:00-20:00',
        "pic": 'https://static.ledsdk.com/pro/202410/923020791564861441.png',
    },
    {
        "name": '北京京城',
        "address": '北京市朝阳区住邦2000,2号楼',
        "phone": '95065',
        "businessHours": '8:00-20:00',
        "pic": 'https://static.ledsdk.com/pro/202410/923020813039370241.png',
    },
    {
        "name": '北京融易',
        "address": '北京市朝阳区光华路5号世纪财富中心东座',
        "phone": '95065',
        "businessHours": '8:00-20:00',
        "pic": 'https://static.ledsdk.com/pro/202410/923020834514206721.png',
    },
    {
        "name": '成都巨犇',
        "address": '成都市锦江区东大街花样年喜年广场A座',
        "phone": '95065',
        "businessHours": '8:00-20:00',
        "pic": 'https://static.ledsdk.com/pro/202410/925156223534301185.png',
    },
    {
        "name": '广州宝投',
        "address": '广州市天河路228号之一广晟大厦',
        "phone": '95065',
        "businessHours": '8:00-20:00',
        "pic": 'https://static.ledsdk.com/pro/202410/923020873169108993.png',
    },
    {
        "name": '南京友禾',
        "address": '南京市建邺区庐山路158号嘉业国际城4号楼',
        "phone": '95065',
        "businessHours": '8:00-20:00',
        "pic": 'https://static.ledsdk.com/pro/202410/923020886053945345.png',
    },
]