<template>
  <div class="introduce">
    <div class="Headline">拍掌柜简介</div>
    <div class="module">
      <div class="section-wrap">
        <div class="section">
          <div class="section-content">
            <div class="p">
              拍掌柜，O2O助贷平台，立足普惠金融行业，旨在为中国4800万小微企业主提供成本更低、效率更高的融资服务，并通过科技沉淀和数据积累为全产业链的线下贷款服务商和金融机构赋能，谨守合规底线，推动整个行业稳健向阳发展。
            </div>
            <div class="p">
              2023年8月，拍掌柜平台已累计为超过10万小微客户提供服务，其服务已覆盖北京、上海、深圳、杭州、西安、长沙、成都等40多座城市，与100多家优质线下门店进行合作。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="module">
      <div class="title">品牌简介</div>
      <div class="section-wrap">
        <div class="section">
          <div class="section-title">发展背景</div>
          <div class="section-content">
            <div class="p">
              拍掌柜隶属于信也科技子品牌，2021年底在“线上需求、线下供给”的助贷平台合规背景下应运而生。
            </div>
            <div class="p">
              信也科技是在美上市的金融科技集团，致力于通过大数据、人工智能、区块链等技术实现“科技，让金融更美好”的使命，推动金融服务从可获得进一步向可负担、可信任和可享受，进化成为受用户欢迎、受伙伴信任的金融科技品牌。
            </div>
            <div class="p">
              信也科技成功跨越行业周期，全面转型为助贷机构。同时，业务规模稳步发展，并且与抖音、快手等头部信息流平台建立合作，持续开发优质场景改善成交体验。在信也科技的支持下，拍掌柜的发展稳步向前。
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section-title">拍掌柜logo定位</div>
          <div class="section-content">
            <div class="p">
              蓝色方块象征抽象的数据信息模块，彰显数字与科技感。对于方块其中两角进行了圆角处理，与中间的菱形孔共同构成近似古代钱币的形状，对应了“掌柜”一词给人的印象，也同时象征了财富的概念。菱形贯穿方块，象征数据的交换与互动，以现代科技打开财富通道。文字在保留辨识度的同时，将复杂的笔画简化，以较为方正的字形呈现，更显稳重大气。
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section-title">服务宗旨</div>
          <div class="section-content">
            <div class="p"><strong>服务小微：</strong>聚焦小微企业融资慢、融资难问题</div>
            <div class="p"><strong>赋能行业：</strong>为全产业链的服务商、金融机构赋能，推动行业发展</div>
            <div class="p"><strong>合规经营：</strong>紧跟行业政策和法律法规，始终将合规放在第一位</div>
          </div>
        </div>
        <div class="section">
          <div class="section-title">产品介绍</div>
          <div class="section-content product">
            <div class="p">
              拍掌柜建立O2O贷款渠道，不仅提供部署专用系统外呼设备服务，还有SASS技术服务、CRM系统、企微平台服务、电子签章、线上收款等，助力合作商高效跟进用户。凭借强大的科技能力和多年积累的业务实践经验，拍掌柜打造了集线上获客、智能营销、销售管理、产品推荐等功能于一身的海陆云系统。
            </div>
            <div class="list">
              <div class="item">
                <strong class="item-title">额度高</strong>
                <div class="item-desc">最高可贷3000万</div>
              </div>
              <div class="item">
                <strong class="item-title">利息低</strong>
                <div class="item-desc">银行放款，利息最低3.3%起</div>
              </div>
              <div class="item">
                <strong class="item-title">年限长</strong>
                <div class="item-desc">最长10-20年期</div>
              </div>
              <div class="item">
                <strong class="item-title">服务好</strong>
                <div class="item-desc">配备专属客户经理，提供一站式资金解决方案</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="module">
      <div class="title">服务优势</div>
      <div class="section-wrap">
        <div class="section">
          <div class="section-title">科技驱动</div>
          <div class="section-content">
            <div class="p">集团沉淀的科技能力，可以为业务发展提供包括海陆云客户管理系统、投放系统等科技基础。</div>
          </div>
        </div>
        <div class="section">
          <div class="section-title">精准投放</div>
          <div class="section-content">
            <div class="p">
              经过多年的积累，拍掌柜具有行业领先的投放能力，获得头部信息流平台的准入资质；大数据的沉淀不断提高投放的精准度和效率。
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section-title">安全合规</div>
          <div class="section-content">
            <div class="p">
              集团的风控体系可以智能化地为商机转化负责，将合法合规放在第一位，遵从金融本质，以数据为基石。
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section-title">品牌保障</div>
          <div class="section-content">
            <div class="p">
              信也集团旗下的拍拍贷产品，创立至2023年6月已经16年，具备资金保障和金融科技发展经验、以及完善的信息流投放技术，给拍掌柜产品的发展创造条件。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="module news">
      <div class="title">新闻动态</div>
      <div class="section-wrap">
        <div class="section">
          <div class="section-content">
            <div class="p">
              2023年2月16日，拍掌柜商务负责人李鑫涛宣布开启拍掌柜“远航计划”，实现金融和产业双向并进，即向金融机构分享小微服务科技经验，提升小微精细化服务效率；向线下服务商开放小微融资共建通道，优化小微融资渠道。拍掌柜打造的精细化小微企业融资服务渠道，利用线上线下结合模式，正深入推进金融场景体系建设，并通过人工智能和大数据的技术升级，引金融“活水”纾困小微，针对细分垂直领域的场景特点，为小微企业融资提供产品智能匹配服务，同时通过为服务商提供专业贴心的线下服务，实现金融和小微需求的高效连接，以缓解小微企业压力，进而提升小微产业链整体效能。
            </div>
            <div class="p">
              拍掌柜通过打造房抵O2O模式，为门店打造专属私域流量，业务卖点受到市场的认可。消金界了解到，其盈利模式是向B端中介机构收费，而非向C端用户收取利息。这种模式可以说是一种创新，可以实现资源效用的最大化。
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.introduce {
  width: 100%;
  min-height: 100vh;
  .setFlexPos(column, flex-start, center);

  .Headline {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    margin-top: 50px;
  }

  .module {
    .title {
      font-size: 25px;
      font-weight: bold;
      margin-top: 25px;
    }
    .section-wrap {
      width: @section-width;
      margin-top: 25px;
      border-radius: 10px;
      padding: 20px;
      background: #f5f9fa;

      .section {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0px;
        }
        &-title {
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        &-content {
          font-size: 16px;
          font-weight: 350;
          line-height: 25px;
          text-align: justify;
          //   margin-top: 30px;
          line-height: 2;
        }
        .product {
          .list {
            width: 100%;
            text-align: center;
            .setFlexPos(row, flex-start, center);
            margin-top: 20px;
            .item {
              .setFlexPos(column, flex-start, flex-start);
              margin-right: 50px;
            }
          }
        }
      }
    }
  }
  .news {
    margin-bottom: 100px;
  }
}
</style>
