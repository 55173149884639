<template>
  <div data-v-ebef4e9c="" class="container">
    <h1 data-v-ebef4e9c="" align="center">个人信息处理规则</h1>
    <div data-v-ebef4e9c="">
      <p>
        <strong>【上海山桐科技有限公司】（下称：我们）</strong
        >非常重视用户信息的保护。您在使用我们提供的<strong>拍掌柜贷款咨询服务（以下简称“本服务”）时</strong>，我们可能会收集和使用您的相关信息。我们希望通过个人信息处理规则（以下简称“本规则”）向您说明在我们在向您提供拍掌柜贷款咨询服务时，我们如何收集、使用、共享、保护您的信息，以进一步保障您的信息安全。<strong
          >在使用本服务时，您需要在相应页面填写相关信息，请您务必仔细阅读本规则。您一旦主动在相应页面填写信息并进行提交操作，即意味着您同意我们按照本规则收集、使用、共享您的相关信息。</strong
        >
      </p>
      <p>
        拍掌柜贷款咨询服务，指由我们根据您提交的相关信息，将您提交的申请匹配并推荐给我们的合作方，并由我们的合作方线下为您推荐具体贷款服务的合作金融机构。
      </p>
      <p><strong> 一、个人信息的收集</strong></p>
      <p>
        1、我们提供贷款咨询服务时，会收集您的<strong>姓名、手机号、房产所在地</strong>信息，为了给您提供更精准的贷款咨询服务，您可以选择提供其他非必须信息，包括<strong>年龄、房产类型、房产价值、需求金额、工作情况、公积金情况、房屋贷款情况、需求期限、婚姻情况、需求期限、婚姻情况、产权归属、月收入情况、负债情况、房屋贷款余值、征信情况、车产情况</strong>。请您注意，具体需要填写的信息可能会根据我们提供的产品/服务的不同而有所差异，请以届时向您展示的服务以及所对应的要求填写相关个人信息，但我们会在下文向您说明我们可能收集的全部个人信息的收集和使用规则。
      </p>
      <p>
        2、您需要注意的是，以上个人信息均是您自愿提供，且您需要保证您提供的个人信息的真实性、有效性和合法性。您有权拒绝提供，但可能无法正常使用相应的服务、功能或者无法达到服务拟达到的效果。
      </p>
      <p>
        3、<strong>本服务仅供成年人使用，如果您是18周岁以下的未成年人，请通过本规则载明的联系方式与我们联系以便我们可以尽快删除这些信息</strong>。
      </p>
      <p><strong>二、个人信息的使用和共享 </strong></p>
      <p>1、您同意，我们可以通过以下方式对个人信息进行使用和共享：</p>
      <p>
        （1）为满足您的需求，您同意<strong
          >我们及我们的合作方就您可能适合的贷款产品、信息和服务通过电话、微信等方式与您联系。</strong
        >
      </p>
      <p>
        （2）<strong>为了更好的提供服务，我们及我们的合作方与您沟通时，</strong>您同意<strong>我们会记录相关沟通情况并录音</strong>。
      </p>
      <p>
        （3）您同意，为保障使用安全或进行必要的产品使用分析，<strong>我们可能会收集使用用于维护产品或服务安全稳定运行的必要信息（例如您使用服务提交的表单信息和相关日志信息）</strong>；
      </p>
      <p>
        （4）<strong
          >我们可能会使用您的脱敏、去标识化/匿名化个人信息与我们的合作方共享，或者/并向合法留存您个人信息的我们的合作方查询、核验基于您的个人信息加工处理后的去标识化信息，以便更好地改善我们的产品和服务。</strong
        >
      </p>
      <p>
        （5）您同意，基于我们的合作方向您推荐贷款产品所必须，<strong>我们可能会向我们的合作方共享您的个人信息，</strong>由我们的合作方在合法正当以及您已经向其授权的范围内使用（例如与您及时进行联系）。
      </p>
      <p>
        （6）<strong
          >我们可能向您发送有关产品、服务或相关活动的信息，但请你放心，如你不愿接受这些信息，你可以通过信息中的退订方式进行退订，也可以直接与我们联系进行退订。</strong
        >
      </p>
      <p>
        （7）如您拟撤回授权/删除您提交的个人信息的，您可以通过<strong>客服电话（95065）</strong>与我们联系，我们将尽快配合处理，如我们的合作方在您撤回授权或申请删除前已经获得了您的相关信息，我们会敦促其响应您的诉求并要求其删除您的相关信息。
      </p>
      <p>
        2、<strong>我们将严格保护您的个人信息安全。</strong>我们将采用适当制度、安全技术和程序等措施来保护您的个人信息不被未经授权的访问、使用或泄漏。我们亦将促使本规则所述各接收和使用方尽力保护和不予泄露您的个人信息。
      </p>
      <p>
        3、我们将要求并敦促个人信息接收方遵循本规则和法律法规的规定，谨慎使用其接收到的个人信息，如其发生超越本规则范围使用您的个人信息，进而侵害您合法权益的情形，我们将协助您追究该接收方的法律责任。
      </p>
      <p><strong> 三、适用法律</strong></p>
      <p>
        1、本规则适用中华人民共和国法律。本规则的签订、履行、终止、解释等而产生的纠纷均由双方协商解决，如协商无法解决，则任何一方可向<strong>上海市浦东新区所在地</strong>有管辖权的人民法院起诉。
      </p>
      <p>
        2、如果您对信息保护问题有任何疑问或选择取消授权的，您可以通过<strong>客服电话（95065）</strong>联系我们。
      </p>
      <p><strong>四、变更 </strong></p>
      <p>
        <strong
          >我们会适时对本规则进行修订，该等修订构成本规则的一部分我们会将修改后的规则予以发布。在该种情况下，若您继续使用我们的服务，即表示同意接受经修订的规则的约束。</strong
        >
      </p>
      <br />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},

  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.container {
  width: 1180px;
  margin: 30px auto 50px;
  font-size: 16px;
  line-height: 2;
}
</style>
