export function delCookie(name) {
  let date = new Date();
  date.setTime(date.getTime() - 1);
  let cval = name;
  if (cval != null)
    document.cookie = `${name}=${cval};expires=${date.toUTCString()};path=/;domain=.paizg.com`;
  if (cval != null)
    document.cookie = `${name}=${cval};expires=${date.toUTCString()};domain=.paizg.com`;
}

export function getCookie(c_name) {
  if (document.cookie.length > 0) {
    // var c_start = document.cookie.indexOf(c_name + "=")
    // if (c_start != -1) {
    //     c_start = c_start + c_name.length + 1
    //     var c_end = document.cookie.indexOf(";", c_start)
    //     if (c_end == -1) c_end = document.cookie.length
    //         return unescape(document.cookie.substring(c_start, c_end))
    // }
    const strcookie = document.cookie; //获取cookie字符串
    const arrcookie = strcookie.split(";"); //分割
    //遍历匹配
    for (let i = 0; i < arrcookie.length; i++) {
      const arr = arrcookie[i].trim().split("=");
      if (arr[0] === c_name) {
        return arr[1];
      }
    }
    return "";
  }
  return "";
}

export function setCookie(c_name, value, time) {
  let exDate = new Date(new Date().getTime() + time * 3600 * 1000);
  document.cookie = `${c_name}=${escape(value)}${
    time == null
      ? ""
      : `;expires=${
          // @ts-ignore
          exDate.toGMTString()
        }`
  }`;
}
