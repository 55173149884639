import {
  Button,
  Carousel,
  Cascader,
  Checkbox,
  Form,
  FormModel,
  Input,
  Select,
  message,
} from "ant-design-vue";

const registerVenderComponents = (Vue) => {
  Vue.use(Carousel);
  Vue.use(Form);
  Vue.use(Input);
  Vue.use(Select);
  Vue.use(Button);
  Vue.use(FormModel);
  Vue.use(Cascader);
  Vue.use(Checkbox);
  Vue.prototype.$message = message;
};

// { tag: '', vue: someComponent }
const customComponents = [];

const registerCustomComponents = (Vue, components) => {
  components.map((component) => Vue.component(component.tag, component.vue));
};

export const registerComponents = (Vue) => {
  registerCustomComponents(Vue, customComponents);
  registerVenderComponents(Vue);
};
