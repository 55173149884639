import service from "../index";

// 首页动态数据
export const queryStatisticsRecordList = (params) =>
  service.post("/xpfsapp/statisticsRecord/queryStatisticsRecordList", params);

// 客户申请合作
export const loanApplyByDeliver = (params) =>
  service.post(
    "/mortgageService/trafficPlatformDock/loanApplyByDeliver",
    params
  );

// 客户申请合作
export const merchantLoanApplyByDeliver = (params) =>
  service.post("/mortgageService/trafficPlatformDock/deliverForStore", params);

// 判断线索是否有效
export const getOrderStatusV3 = (params) =>
  service.post("/mortgageService/trafficPlatformDock/getOrderStatusV3", params);

// 曝光接口
export const landingPageReport = (params) =>
  service.post("/xpfsapp/deliverModel/landingPageReport", params);
