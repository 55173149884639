<template>
  <div class="store">
    <TopHeader :activeSection="activeSection" :class="['header']"/>
    <div class="card-list">
      <store-card v-for="(item, index) in storeData" :key="index" :card="item" />
    </div>
  </div>
</template>

<script>
import StoreCard from "./storeCard.vue";
import TopHeader from "@main/components/common/header";
import { pointCommonParam } from "@main/util/util";
import { storeData } from "./storeData";

export default {
  components: { TopHeader, StoreCard },
  data() {
    return {
      activeSection: "", // 用于跟踪当前活动的锚点
      storeData,
    };
  }
};
</script>

<style lang="less" scoped>
.store {
  width: 100%;
  min-height: 100vh;
  background: #ffffff;
  padding-bottom: 125px;
  .header {
    background:#F5F9FA;
  }
  .card-list {
    margin: 30px auto 0;
  }
}
</style>
