import Vue from "vue";
import VueRouter from "vue-router";
import Apply from "../views/apply";
import ApplyResult from "../views/applyResult";
import Home from "../views/home";
import Landing from "../views/landing";
import PersonalInfo from "../views/protocols/personalInfo";
import Protocol from "../views/protocols/protocol";
import Sealand from "../views/seaLand";
import Introduce from "../views/introduce";
import Store from "../views/store";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export const routes = [
  {
    path: "/",
    redirect: "/home/",
  },
  {
    path: "/home/",
    name: "Home",
    component: Home,
  },
  {
    path: "/sealand/",
    name: "Sealand",
    component: Sealand,
  },
  {
    path: "/introduce/",
    name: "Introduce",
    component: Introduce,
  },
  {
    path: "/apply/",
    name: "Apply",
    component: Apply,
  },
  {
    path: "/landing/",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/apply-merchant/",
    name: "ApplyMerchant",
    component: Apply,
  },
  {
    path: "/apply-result/",
    name: "applyResult",
    component: ApplyResult,
  },
  {
    path: "/protocol/",
    name: "Protocol",
    component: Protocol,
  },
  {
    path: "/personal-info/",
    name: "PersonalInfo",
    component: PersonalInfo,
  },
  {
    path: "/store/",
    name: "Store",
    component: Store,
  },
];

export const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});
