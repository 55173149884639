<template>
  <div data-v-ebef4e9c="" class="container">
    <h1 data-v-ebef4e9c="" align="center">用户服务协议</h1>
    <div data-v-ebef4e9c="">
<p>拍掌柜用户服务协议</p>
<p>&nbsp;</p>
<p><strong>尊敬的用户（以下亦称&ldquo;用户&rdquo;或&ldquo;您&rdquo;），很高兴为您提供拍掌柜服务（包括但不限于贷款咨询等）。在您使用上海山桐科技有限公司（下称&ldquo;我们&rdquo;）提供的上述服务时请认真阅读如下条款，特别是黑体加粗部分</strong>。<strong>为方便您使用本服务，您通过网络页面点击或以其他形式确认《拍掌柜用户服务协议》（下称"本协议"）的，即视为您已充分理解其中所有条款，并同意与我们订立本协议且接受本协议的约束。</strong></p>
<p><strong>我们本身不从事贷款业务，具体是由我们为您匹配的第三方机构为您提供贷款咨询服务，第三方机构为您推荐贷款产品和金融机构，并由该第三方承担相应责任。您在选择该第三方推荐的贷款产品或接受贷款咨询服务前应仔细阅读相关信息。</strong></p>
<p>&nbsp;</p>
<p>第一条 相关定义</p>
<p><strong>1、拍掌柜服务</strong>：指<strong>上海山桐科技有限公司</strong>根据您提交的相关信息，根据您提交的申请匹配并推荐给我们的合作的<strong>第三方机构</strong>；并由我们的合作的<strong>第三方机构</strong>线下为您推荐具体贷款服务的合作金融机构。</p>
<p><strong>第三方机构提供的具体服务内容以实际为您提供服务的第三方与您约定的为准。</strong></p>
<p>2、第三方：指与我们直接或间接合作的为用户提供贷款咨询服务的第三方机构。</p>
<p>3、资金方/金融机构：指向用户提供贷款产品的持牌金融机构。</p>
<p>&nbsp;</p>
<p>第二条 适用主体及范围</p>
<p>1、本协议视为您与拍掌柜服务提供方上海山桐科技有限公司及其合作方之间的服务依据条款，即您同意向我们申请拍掌柜服务所订立的协议。</p>
<p>2、<strong>由第三方为您提供贷款咨询、财务咨询服务的，您还应当遵守第三方的各项规则；您与第三方之间就服务提供产生的争议由第三方负责。您也可以通过客服电话（95065）投诉至我们，我们可以协助解决以保障您的合法权利。</strong></p>
<p>&nbsp;</p>
<p>第三条 拍掌柜服务开通</p>
<p>1、您申请开通拍掌柜服务时，<strong>需要提交您的个人资料信息（具体以页面展示为准），并保证您提供的个人资料真实、准确、完整、合法有效，如有变动，您应及时更新告知。如您提供的资料不合法、不真实、不准确、不详尽，由此引起的损失及法律责任将由您自行承担，并且我们保留终止您参与该服务的权利。</strong></p>
<p><strong>2、我们坚决抵制并严禁第三方机构擅自以您的名义为您申请贷款产品、诱导您伪造资料骗取资金方贷款、向您提供受托支付账户、提供无资质、超过规定利息的违规垫资业务、泄露您的个人信息等违法违规行为，为保障您的权利，您在接受第三方机构服务过程中，您可拨打我们客服电话（</strong>95065）<strong>，核实第三方机构各项信息，并可以向我们投诉第三方机构。</strong></p>
<p>3、<strong>我们坚决抵制并严禁第三方机构飞单（指第三方机构绕过拍掌柜私自为您提供服务）行为，同时为保障您的合法权益，我们可能会向您微信或者电话回访，请您配合我们的回访。</strong></p>
<p>4、为更好的向您提供服务，我们有权基于自身业务发展调整全部或部分拍掌柜服务规则。我们就前述权益调整将在相应服务页面进行展示，但该等调整不会削减已申请该服务的用户的合法权益。</p>
<p>&nbsp;</p>
<p><strong>第四条 </strong>用户的权利和义务</p>
<p>1、如您有其他与拍掌柜服务相关的问题，可以通过我们公布的<strong>客服电话（95065）</strong>进行反馈。</p>
<p>2、因不可抗力造成服务的中断或其它缺陷，我们不承担任何责任，但将尽力减少因此给用户造成的损失和影响。</p>
<p>3、您知悉并同意，<strong>我们可能向您发送有关产品、服务或相关活动的信息，但请你放心，如你不愿接受这些信息，你可以通过信息中提供的退订方式进行退订，也可以直接与我们联系进行退订。</strong></p>
<p>4、<strong>您确认拍掌柜服务仅限您本人使用，同时，您保证，您将合理使用拍掌柜服务，不利用该服务非法获利，不以任何形式转让您所享有的权益，不以任何形式将您所享有的拍掌柜服务借给他人使用，如我们有合理理由怀疑您存在不当使用拍掌柜服务的行为时，我们有权终止为您服务，因此产生的相关责任及损失均由您自行承担，给我们造成损失的，我们保留向您追偿的权利。</strong></p>
<p>5、我们有权自行决定是否接受您的拍掌柜服务开通申请，并且当您违反约定使用权益时有权取消您拍掌柜服务资格。</p>
<p>6、您理解并保证，您在使用拍掌柜服务过程中遵守诚实信用原则。<strong>如我们发现或有合理理由怀疑您存在以下任一情形的，我们有权拒绝您的拍掌柜服务开通申请；若已开通的，我们有权单方面终止为您提供拍掌柜服务：</strong></p>
<p>6.1通过任何不当手段或以违反诚实信用原则的方式开通拍掌柜服务的，包括但不限于通过恶意软件绕过我们设定的正常流程开通拍掌柜服务；</p>
<p>6.2您提供的资料不合法、不真实，包括但不限于盗用他人信息；</p>
<p>6.3您向资金方申请的贷款产品并非用于您与资金方另行约定的用途的；</p>
<p>6.4我们有合理理由怀疑您存在违反诚实信用原则的其他行为。</p>
<p>&nbsp;</p>
<p><strong>第五条 用户个人信息保护</strong></p>
<p>1、<strong>保护用户个人信息是我们的一项基本原则，</strong>为保证您正常使用拍掌柜服务，<strong>您了解并同意，我们会收集、使用处理您在拍掌柜页面上填写相关信息等，用于为您提供专业的咨询服务以及联系您</strong>。</p>
<p>2、除法律规定及您授权之外，我们不会向第三方透露、公开您的个人信息，保障您的个人信息安全。详细的个人信息保护规则见<strong>《个人信息处理规则》</strong>。</p>
<p>&nbsp;</p>
<p>第六条 适用法律及争议解决</p>
<p>1、本协议的签订、履行、终止、解释和争议解决受中国法律管辖（大陆地区法律）。</p>
<p><strong>2、因本协议引发的或与本协议有关的争议，由我们与您协商解决，也可由有关部门调解；协商或调解不成的，由本协议签署地上海市浦东新区有管辖权的人民法院提起诉讼。</strong></p>
<p>&nbsp;</p>
<p>第七条 通知</p>
<p>1、我们通过您留下的电话、邮箱、微信等通联系方式向您发出通知，其中以电子的方式发出的书面通知，包括但不限于在我们平台公告，向您提供的联系电话发送手机短信，向您提供的电子邮件地址发送电子邮件，向您的账号发送系统消息以及站内信信息，在发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送达。</p>
<p>2、对于在我们上因交易活动引起的任何纠纷，您同意司法机关（包括但不限于人民法院）可以通过手机短信、电子邮件等现代通讯方式或邮寄方式向您送达法律文书（包括但不限于诉讼文书）。您指定接收法律文书的手机号码等联系方式为您在我们注册、更新时提供的手机号以及在注册我们用户时生成的账号，司法机关向上述联系方式发出法律文书即视为送达。您指定的邮寄地址为您的法定联系地址或您提供的有效联系地址。</p>
<p>3、您同意司法机关可采取以上一种或多种送达方式向您送达法律文书，司法机关采取多种方式向您送达法律文书，送达时间以上述送达方式中最先送达的为准。</p>
<p>4、如果因提供的联系方式不确切，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，由您自行承担由此可能产生的法律后果。</p>
<p>&nbsp;</p>
<p>第八条 其他</p>
<p>1、如因不可抗力或其他本站无法控制的原因是本服务无法及时提供或无法按本协议进行的，我们会合理地尽力协助处理善后事宜。</p>
<p><strong>2、&nbsp;我们会适时对本协议进行修订，该等修订构成本协议的一部分我们会将修改后的协议予以发布。在该种情况下，若您继续使用我们的服务，即表示同意接受经修订的协议的约束。</strong></p>
<p>&nbsp;</p>
<p>附录-第三方合作机构</p>
<table>
<tbody>
<tr>
<td>
<p><strong>门店名称</strong></p>
</td>
<td>
<p><strong>门店地址</strong></p>
</td>
</tr>
<tr>
<td>
<p>常州点汇</p>
</td>
<td>
<p>常州市武进区万达中心写字楼801</p>
</td>
</tr>
<tr>
<td>
<p>杭州鼎寰</p>
</td>
<td>
<p>杭州市上城区钱塘航空大厦2幢1602</p>
</td>
</tr>
<tr>
<td>
<p>上海国岚</p>
</td>
<td>
<p>上海市长宁区仙霞路137号905室</p>
</td>
</tr>
<tr>
<td>
<p>杭州君贺</p>
</td>
<td>
<p>杭州市上城城区环站东路768号富亿花园中心T2幢518室</p>
</td>
</tr>
<tr>
<td>
<p>北京中海</p>
</td>
<td>
<p>北京市朝阳区三元桥第三置业A座2301</p>
</td>
</tr>
<tr>
<td>
<p>天津优优</p>
</td>
<td>
<p>天津市和平区环球金融中心2107</p>
</td>
</tr>
<tr>
<td>
<p>兰州君堂</p>
</td>
<td>
<p>甘肃省兰州市城关区庆阳路149号亚欧国际大厦第29层2903室</p>
</td>
</tr>
<tr>
<td>
<p>青岛开旺</p>
</td>
<td>
<p>青岛市市北区敦化路119号凯景大厦（河北银行）7楼706室</p>
</td>
</tr>
<tr>
<td>
<p>珠海普惠</p>
</td>
<td>
<p>珠海市香洲区景山路92号东大商业中心701室</p>
</td>
</tr>
<tr>
<td>
<p>上海国湖</p>
</td>
<td>
<p>上海市普陀区金沙江路938号华大科技园E1栋1208室</p>
</td>
</tr>
<tr>
<td>
<p>上海助贷</p>
</td>
<td>
<p>长宁区长宁路1027号兆丰广场1001室</p>
</td>
</tr>
<tr>
<td>
<p>杭州芽杭</p>
</td>
<td>
<p>杭州市钱江国际时代广场2号楼4007-2</p>
</td>
</tr>
<tr>
<td>
<p>深圳钱多多</p>
</td>
<td>
<p>深圳市南山区生态科技园6栋1101-1105</p>
</td>
</tr>
<tr>
<td>
<p>南通君堂</p>
</td>
<td>
<p>南通市崇川区中南百货南区2906</p>
</td>
</tr>
<tr>
<td>
<p>合肥聚鑫德</p>
</td>
<td>
<p>合肥市蜀山区蔚蓝商务港F座1106室</p>
</td>
</tr>
<tr>
<td>
<p>苏州享烁</p>
</td>
<td>
<p>苏州市高新区竹园路209号2号楼1402-03</p>
</td>
</tr>
<tr>
<td>
<p>成都米袋</p>
</td>
<td>
<p>四川省成都市青羊区西御街3号领地中心东塔29楼call米金融</p>
</td>
</tr>
<tr>
<td>
<p>温州易融网</p>
</td>
<td>
<p>温州市鹿城区车站大道华盟商务广场3510室</p>
</td>
</tr>
<tr>
<td>
<p>福州银信</p>
</td>
<td>
<p>福州市台江区富力中心A座2楼222</p>
</td>
</tr>
<tr>
<td>
<p>苏州鼎禄</p>
</td>
<td>
<p>苏州市虎丘区狮山路76号华福大厦1201室</p>
</td>
</tr>
<tr>
<td>
<p>滁州君堂</p>
</td>
<td>
<p>安徽省滁州市南谯区担子街道瑶海大市场东陈村委会301室</p>
</td>
</tr>
<tr>
<td>
<p>佛山普惠</p>
</td>
<td>
<p>佛山市禅城区季华五路万科金融中心B座1910</p>
</td>
</tr>
<tr>
<td>
<p>西安君堂</p>
</td>
<td>
<p>陕西省西安市经济技术开发区未央路125号第五国际A座2408室</p>
</td>
</tr>
<tr>
<td>
<p>杭州明银</p>
</td>
<td>
<p>杭州市拱墅区运河万科A2-905</p>
</td>
</tr>
<tr>
<td>
<p>南京诚享顺</p>
</td>
<td>
<p>南京市鼓楼区中南国际大厦15楼</p>
</td>
</tr>
<tr>
<td>
<p>上海友鑫金融</p>
</td>
<td>
<p>上海市杨浦区黄兴路1800号3号楼1209室</p>
</td>
</tr>
<tr>
<td>
<p>成都祥盛和</p>
</td>
<td>
<p>四川省成都市崇州市崇庆街道世纪大道1396号2栋1楼10号</p>
</td>
</tr>
<tr>
<td>
<p>合肥银翰</p>
</td>
<td>
<p>安徽省合肥市政务区新城国际B座1710室</p>
</td>
</tr>
<tr>
<td>
<p>天津中津</p>
</td>
<td>
<p>天津市南开区金融街中心C座23楼</p>
</td>
</tr>
<tr>
<td>
<p>昆明臻致</p>
</td>
<td>
<p>云南省昆明市盘龙区香博商厦2幢1001室</p>
</td>
</tr>
<tr>
<td>
<p>西安叶子</p>
</td>
<td>
<p>陕西省西安市高新区锦业路12号迈科商业中心1202室</p>
</td>
</tr>
<tr>
<td>
<p>上海翔稳</p>
</td>
<td>
<p>川沙镇川沙路5558弄5号302</p>
</td>
</tr>
<tr>
<td>
<p>上海橙蚁</p>
</td>
<td>
<p>上海市浦东新区</p>
</td>
</tr>
<tr>
<td>
<p>北京纳良</p>
</td>
<td>
<p>北京市朝阳区吉庆里14号佳汇国际中心A座10层1002</p>
</td>
</tr>
<tr>
<td>
<p>重庆盛融商务</p>
</td>
<td>
<p>重庆市渝北区新南路166号龙湖MOCO4栋19楼</p>
</td>
</tr>
<tr>
<td>
<p>北京有它帮帮</p>
</td>
<td>
<p>北京市丰台区南三环西路16号楼3号楼16层1901</p>
</td>
</tr>
<tr>
<td>
<p>成都以太</p>
</td>
<td>
<p>四川省成都市锦江区磨房街80号2702室</p>
</td>
</tr>
<tr>
<td>
<p>重庆盛融担保</p>
</td>
<td>
<p>重庆市渝北区新南路166号龙湖MOCO 4栋19楼</p>
</td>
</tr>
<tr>
<td>
<p>青岛习相远</p>
</td>
<td>
<p>青岛市市北区卓越世纪中心3座1016室</p>
</td>
</tr>
<tr>
<td>
<p>盐城摩尔龙</p>
</td>
<td>
<p>盐城市金融城3号楼2201室</p>
</td>
</tr>
<tr>
<td>
<p>杭州银汇达</p>
</td>
<td>
<p>杭州市上城区婺江路东铁大厦402室</p>
</td>
</tr>
<tr>
<td>
<p>常州玖零</p>
</td>
<td>
<p>江苏常州新北区典雅花园1号楼725-727室</p>
</td>
</tr>
<tr>
<td>
<p>广州普惠</p>
</td>
<td>
<p>广州市天河区黄埔大道西76号1303-1306</p>
</td>
</tr>
<tr>
<td>
<p>重庆锦泰</p>
</td>
<td>
<p>重庆市江北区庆云路16号7-7号</p>
</td>
</tr>
<tr>
<td>
<p>温州君贺</p>
</td>
<td>
<p>温州鹿城华盟商务广场2804室</p>
</td>
</tr>
<tr>
<td>
<p>杭州联营</p>
</td>
<td>
<p>杭州市上城区富春路308-309号华成国际发展大厦902室</p>
</td>
</tr>
<tr>
<td>
<p>上海融戴</p>
</td>
<td>
<p>上海市浦东新区世纪大道1090号斯米克大厦622</p>
</td>
</tr>
<tr>
<td>
<p>苏州助贷</p>
</td>
<td>
<p>苏州工业园区旺墩路269号星座商务广场1栋902室</p>
</td>
</tr>
<tr>
<td>
<p>武汉源兴</p>
</td>
<td>
<p>武汉市江汉区建设大道538号同成广场a座4单元2903室</p>
</td>
</tr>
<tr>
<td>
<p>北京齐明</p>
</td>
<td>
<p>北京通州区保利大都会写字楼B座707室</p>
</td>
</tr>
<tr>
<td>
<p>上海洪御</p>
</td>
<td>
<p>上海市浦东新区东方路971号钱江大厦23D</p>
</td>
</tr>
<tr>
<td>
<p>昆明个贷</p>
</td>
<td>
<p>云南省昆明市盘龙区小坝路金尚俊园C座17楼</p>
</td>
</tr>
<tr>
<td>
<p>厦门众合银华</p>
</td>
<td>
<p>厦门市思明区湖滨南路334-1号1211室</p>
</td>
</tr>
<tr>
<td>
<p>无锡呗泉</p>
</td>
<td>
<p>无锡市梁溪区大东方B座22F</p>
</td>
</tr>
<tr>
<td>
<p>宁波神灯</p>
</td>
<td>
<p>宁波市鄞州区滨江商业广场2期7号1602</p>
</td>
</tr>
<tr>
<td>
<p>金华格子</p>
</td>
<td>
<p>浙江省金华市金东区万达广场5号楼415</p>
</td>
</tr>
<tr>
<td>
<p>苏州众心</p>
</td>
<td>
<p>苏州市苏悦广场北楼605</p>
</td>
</tr>
<tr>
<td>
<p>福州天虹</p>
</td>
<td>
<p>福建省福州市台江区茶亭街道广达路68号金源大广场东区第28层2802单元</p>
</td>
</tr>
<tr>
<td>
<p>重庆乐享融</p>
</td>
<td>
<p>重庆市渝北区恒大中渝广场3号楼10-1</p>
</td>
</tr>
<tr>
<td>
<p>长沙银与</p>
</td>
<td>
<p>长沙开福区芙蓉中路109号华创国际广场B座918室</p>
</td>
</tr>
<tr>
<td>
<p>成都乐享融</p>
</td>
<td>
<p>四川省成都市二环路东二段508号成华科技大厦33a02</p>
</td>
</tr>
<tr>
<td>
<p>石家庄中津</p>
</td>
<td>
<p>河北省石家庄市桥西区华润万象城B座1012</p>
</td>
</tr>
<tr>
<td>
<p>长沙尚昇</p>
</td>
<td>
<p>雨花区红星国际大厦A座2030</p>
</td>
</tr>
<tr>
<td>
<p>无锡明纪</p>
</td>
<td>
<p>无锡市经开区昌兴国际金融大厦</p>
</td>
</tr>
<tr>
<td>
<p>南京鑫时代</p>
</td>
<td>
<p>南京市秦淮区江苏文化大厦1801室</p>
</td>
</tr>
<tr>
<td>
<p>北京晟程时代</p>
</td>
<td>
<p>北京市丰台区草桥东路2号院一号楼首农双创中心大厦702</p>
</td>
</tr>
<tr>
<td>
<p>北京光大国信</p>
</td>
<td>
<p>北京市朝阳区东三环中路39号建外SOHO东区b座1006室</p>
</td>
</tr>
<tr>
<td>
<p>南通中南</p>
</td>
<td>
<p>江苏省南通市海门区金融广场A座202室</p>
</td>
</tr>
<tr>
<td>
<p>武汉摩尔龙</p>
</td>
<td>
<p>武汉市江岸区天悦外滩金融中心702</p>
</td>
</tr>
<tr>
<td>
<p>惠州普惠</p>
</td>
<td>
<p>惠州市惠城区江北帝景商务中心B座1108</p>
</td>
</tr>
<tr>
<td>
<p>成都钱多多</p>
</td>
<td>
<p>成都市锦江区东方广场A座25楼整层</p>
</td>
</tr>
<tr>
<td>
<p>重庆明汇</p>
</td>
<td>
<p>重庆市江北区庆云路 1 号国金中心 T1 办公楼 6 楼</p>
</td>
</tr>
<tr>
<td>
<p>常州招财</p>
</td>
<td>
<p>常州市武进区湖塘镇延政中路5号-23A01</p>
</td>
</tr>
<tr>
<td>
<p>北京融易</p>
</td>
<td>
<p>北京市朝阳区光华路5号世纪财富中心东座 20层2003室</p>
</td>
</tr>
<tr>
<td>
<p>常州融盛</p>
</td>
<td>
<p>常州市武进区大通路200号</p>
</td>
</tr>
<tr>
<td>
<p>北京合生助贷</p>
</td>
<td>
<p>北京市东城区广渠门内大街鼎新大厦520</p>
</td>
</tr>
<tr>
<td>
<p>重庆富丰融</p>
</td>
<td>
<p>重庆两江新区天宫殿街道新南路166号2幢1单元19-4</p>
</td>
</tr>
<tr>
<td>
<p>广州京册</p>
</td>
<td>
<p>广州市天河区华强路3号富力盈力北塔1408</p>
</td>
</tr>
<tr>
<td>
<p>西安巨犇</p>
</td>
<td>
<p>陕西省西安市未央区凤城九路中登文景大厦B座1101</p>
</td>
</tr>
<tr>
<td>
<p>宁波乐丰</p>
</td>
<td>
<p>浙江省宁波市鄞州区和荣大厦A座2603</p>
</td>
</tr>
<tr>
<td>
<p>重庆乾为贵</p>
</td>
<td>
<p>重庆市渝北区龙溪街道红锦大道57号嘉州协信中心2幢20-15、20-16、20-17</p>
</td>
</tr>
<tr>
<td>
<p>北京东雅</p>
</td>
<td>
<p>北京市朝阳区呼家楼雨霖大厦205</p>
</td>
</tr>
<tr>
<td>
<p>北京亿富程</p>
</td>
<td>
<p>北京市朝阳区万达广场10号楼902室</p>
</td>
</tr>
<tr>
<td>
<p>嘉兴格子</p>
</td>
<td>
<p>浙江省嘉兴市经济开发区嘉南美地1幢603室</p>
</td>
</tr>
<tr>
<td>
<p>重庆铂宸</p>
</td>
<td>
<p>重庆市渝中区解放碑平安国际金融中心2501</p>
</td>
</tr>
<tr>
<td>
<p>南京摩尔龙</p>
</td>
<td>
<p>南京市秦淮区汉中路189号平安金融中心7楼</p>
</td>
</tr>
<tr>
<td>
<p>北京袋来</p>
</td>
<td>
<p>北京市朝阳区东三环中路京城机电大厦1105室</p>
</td>
</tr>
<tr>
<td>
<p>南京大德</p>
</td>
<td>
<p>南京市鼓楼区易发信息大厦11A</p>
</td>
</tr>
<tr>
<td>
<p>重庆中正</p>
</td>
<td>
<p>重庆市江北区观音桥街道未来国际大厦34楼</p>
</td>
</tr>
<tr>
<td>
<p>上海百坤</p>
</td>
<td>
<p>上海市浦东新区浦东南路855号1206室（世界广场）</p>
</td>
</tr>
<tr>
<td>
<p>成都祥盛</p>
</td>
<td>
<p>四川省成都市崇州市崇庆街道世纪大道1396号2栋1楼10号</p>
</td>
</tr>
<tr>
<td>
<p>南京捷融</p>
</td>
<td>
<p>南京市秦淮区中山东路198号龙台国际大厦2120室</p>
</td>
</tr>
<tr>
<td>
<p>长沙弘又升</p>
</td>
<td>
<p>湖南省长沙市芙蓉区五一大道826号新华大厦19楼1906-1908</p>
</td>
</tr>
<tr>
<td>
<p>常州摩尔龙</p>
</td>
<td>
<p>常州市武进区延政中路5号常发大厦15楼</p>
</td>
</tr>
<tr>
<td>
<p>苏州玖禧</p>
</td>
<td>
<p>江苏省苏州市姑苏区金阊街道三香路1338号</p>
</td>
</tr>
<tr>
<td>
<p>天津觉策</p>
</td>
<td>
<p>天津市南开区鞍山西道和通大厦1010</p>
</td>
</tr>
<tr>
<td>
<p>广州华腾</p>
</td>
<td>
<p>广州市天河区龙口西路1号1301房</p>
</td>
</tr>
<tr>
<td>
<p>广州麒富</p>
</td>
<td>
<p>广州市海珠区江南大道中路180号3305房</p>
</td>
</tr>
<tr>
<td>
<p>苏州央融</p>
</td>
<td>
<p>苏州工业园区旺墩路269号星座商务广场1幢1005室</p>
</td>
</tr>
<tr>
<td>
<p>深圳信达</p>
</td>
<td>
<p>深圳市龙华区民治街道光浩国际中心A座-13D</p>
</td>
</tr>
<tr>
<td>
<p>武汉全心贷</p>
</td>
<td>
<p>湖北省武汉市武昌区松竹路汉街总部国际C座1007</p>
</td>
</tr>
<tr>
<td>
<p>成都巨犇</p>
</td>
<td>
<p>成都市锦江区东大街花样年喜年广场A座41层</p>
</td>
</tr>
<tr>
<td>
<p>苏州跃驰云</p>
</td>
<td>
<p>苏州工业园区星桂路33号凤凰国际大厦</p>
</td>
</tr>
<tr>
<td>
<p>武汉博诚</p>
</td>
<td>
<p>湖北省武汉市洪山区徐东大街79号中兴时代数贸港第D栋7层03号房</p>
</td>
</tr>
<tr>
<td>
<p>重庆启程</p>
</td>
<td>
<p>重庆市渝中区龙溪街道红锦大道86号中渝广场3幢10-办公3</p>
</td>
</tr>
<tr>
<td>
<p>北京御顺</p>
</td>
<td>
<p>北京市海淀区知春路113号银网中心大厦717室</p>
</td>
</tr>
<tr>
<td>
<p>上海奇弈</p>
</td>
<td>
<p>上海市静安区万荣路700号B1栋</p>
</td>
</tr>
<tr>
<td>
<p>天津鼎图</p>
</td>
<td>
<p>天津市河西区银河大厦1706</p>
</td>
</tr>
<tr>
<td>
<p>青岛拍拍网</p>
</td>
<td>
<p>山东省青岛市市南区延安三路234号1号楼海航万邦中心21层01</p>
</td>
</tr>
<tr>
<td>
<p>无锡云泰</p>
</td>
<td>
<p>梁溪区钟书路99号国金中心3603</p>
</td>
</tr>
<tr>
<td>
<p>上海索阖</p>
</td>
<td>
<p>上海市徐汇区龙华中路596弄上海绿地中心A座1601室</p>
</td>
</tr>
<tr>
<td>
<p>北京财惠来</p>
</td>
<td>
<p>北京市朝阳区新源里16号琨莎中心2座1108-1111</p>
</td>
</tr>
<tr>
<td>
<p>南京七二四</p>
</td>
<td>
<p>南京市建邺区江东中路289号联强国际大厦2404室</p>
</td>
</tr>
<tr>
<td>
<p>苏州恒海</p>
</td>
<td>
<p>苏州高新区金山东路79号13幢711 712室</p>
</td>
</tr>
<tr>
<td>
<p>北京京城</p>
</td>
<td>
<p>北京市朝阳区环宇国际5层505室</p>
</td>
</tr>
<tr>
<td>
<p>北京森强</p>
</td>
<td>
<p>北京市朝阳区中环世贸中心D座18层</p>
</td>
</tr>
<tr>
<td>
<p>西安鑫远达</p>
</td>
<td>
<p>陕西省西安市新城区解放路77号裕朗国际1幢1单元7层10703室</p>
</td>
</tr>
<tr>
<td>
<p>广州鸿骏</p>
</td>
<td>
<p>广州市天河区华夏路30号富力盈通大厦11楼1112a单元</p>
</td>
</tr>
<tr>
<td>
<p>天津尚泽</p>
</td>
<td>
<p>天津市红桥区咸阳北路48号银泰大厦B座1301</p>
</td>
</tr>
<tr>
<td>
<p>南京帮代</p>
</td>
<td>
<p>南京市秦淮区汉中路27号</p>
</td>
</tr>
<tr>
<td>
<p>成都创客时代</p>
</td>
<td>
<p>四川省成都市锦江区东大街下东大街段216号</p>
</td>
</tr>
<tr>
<td>
<p>杭州壹盈</p>
</td>
<td>
<p>杭州东方贸商业中心2栋16层1618室</p>
</td>
</tr>
<tr>
<td>
<p>宁波珍守信</p>
</td>
<td>
<p>宁波鄞州区南部商务区泰康大厦403室</p>
</td>
</tr>
<tr>
<td>
<p>上海俭兰</p>
</td>
<td>
<p>上海市钱江大厦</p>
</td>
</tr>
<tr>
<td>
<p>北京宜昇</p>
</td>
<td>
<p>北京市朝阳区昆泰国际写字楼21层2106</p>
</td>
</tr>
<tr>
<td>
<p>上海数蜂</p>
</td>
<td>
<p>浦东新区浦东大道1868号东方城市大厦10楼</p>
</td>
</tr>
<tr>
<td>
<p>广州银昇</p>
</td>
<td>
<p>广东省广州市天河区华夏路16号富力盈凯广场1710A</p>
</td>
</tr>
<tr>
<td>
<p>杭州助贷</p>
</td>
<td>
<p>杭州市上城区瑞晶国际商务中心1702室</p>
</td>
</tr>
<tr>
<td>
<p>北京金联行</p>
</td>
<td>
<p>北京市朝阳区富力双子座B座21层2105</p>
</td>
</tr>
<tr>
<td>
<p>沈阳津融汇</p>
</td>
<td>
<p>沈阳市铁西区兴华北街18号千缘财富商汇A座1312</p>
</td>
</tr>
<tr>
<td>
<p>成都御顺</p>
</td>
<td>
<p>成都市锦江区人民东路6号SAC东原中心5楼</p>
</td>
</tr>
<tr>
<td>
<p>泰州摩尔龙</p>
</td>
<td>
<p>江苏省泰州市海陵区万达写字楼16楼</p>
</td>
</tr>
<tr>
<td>
<p>杭州咨圆</p>
</td>
<td>
<p>杭州市市民街200号1706室</p>
</td>
</tr>
<tr>
<td>
<p>广州金贷</p>
</td>
<td>
<p>广州市天河区天河东路67号811房</p>
</td>
</tr>
<tr>
<td>
<p>青岛润浦</p>
</td>
<td>
<p>山东省青岛市城阳区国贸大厦926室</p>
</td>
</tr>
<tr>
<td>
<p>上海喻赞</p>
</td>
<td>
<p>上海市静安区沪太路1111号7号楼605室</p>
</td>
</tr>
<tr>
<td>
<p>广州宝投</p>
</td>
<td>
<p>广州市天河区天河东路67号丰兴广场B座703室</p>
</td>
</tr>
<tr>
<td>
<p>北京快融</p>
</td>
<td>
<p>北京市朝阳区光华路乙10号院1号楼众秀大厦2509</p>
</td>
</tr>
<tr>
<td>
<p>南京易凡达</p>
</td>
<td>
<p>南京市秦淮区王府大街8号</p>
</td>
</tr>
<tr>
<td>
<p>苏州昱煊晟</p>
</td>
<td>
<p>苏州市吴中区珠江南路209号柳川大厦C座206室</p>
</td>
</tr>
<tr>
<td>
<p>青岛融中融</p>
</td>
<td>
<p>青岛市北区卓越世纪中心3号楼12b08</p>
</td>
</tr>
<tr>
<td>
<p>西安顺位</p>
</td>
<td>
<p>陕西省西安市碑林区南二环广丰国际2407</p>
</td>
</tr>
<tr>
<td>
<p>上海中南</p>
</td>
<td>
<p>上海市长宁区红宝石路500号东银中心B栋26楼</p>
</td>
</tr>
<tr>
<td>
<p>长沙大晟</p>
</td>
<td>
<p>湖南省长沙市芙蓉区定王台街道建湘路393号世贸商务中心3701</p>
</td>
</tr>
<tr>
<td>
<p>上海芽杭</p>
</td>
<td>
<p>上海市黄浦区河南南路33号</p>
</td>
</tr>
<tr>
<td>
<p>东莞普惠</p>
</td>
<td>
<p>东莞市南城区华凯广场b栋1007室</p>
</td>
</tr>
<tr>
<td>
<p>上海众融</p>
</td>
<td>
<p>上海市浦东新区滨江大道257弄10号陆家嘴滨江中心T1座</p>
</td>
</tr>
<tr>
<td>
<p>广州诺享贷</p>
</td>
<td>
<p>广州市天河区华强路1号珠控国际中心</p>
</td>
</tr>
<tr>
<td>
<p>苏州金吾</p>
</td>
<td>
<p>苏州市吴江区长安路3099号商会大厦2308室</p>
</td>
</tr>
<tr>
<td>
<p>苏州鸿翊</p>
</td>
<td>
<p>苏州高新区狮山天街生活广场13幢705室</p>
</td>
</tr>
<tr>
<td>
<p>成都考拉</p>
</td>
<td>
<p>四川省成都市锦江区东大路IMP环球都汇中心28层2803</p>
</td>
</tr>
<tr>
<td>
<p>福州钜鸿</p>
</td>
<td>
<p>福建省福州市台江区新港街道五一南路1号联信中心大厦第七层706单元</p>
</td>
</tr>
<tr>
<td>
<p>宿迁千御</p>
</td>
<td>
<p>宿迁市水韵城1406</p>
</td>
</tr>
<tr>
<td>
<p>厦门开旺</p>
</td>
<td>
<p>厦门市思明区莲富大厦1409</p>
</td>
</tr>
<tr>
<td>
<p>长沙格子</p>
</td>
<td>
<p>长沙市岳麓区湘江财富金融中心D座2215室</p>
</td>
</tr>
<tr>
<td>
<p>上海嘉信</p>
</td>
<td>
<p>上海市普陀区江宁路1158号友力国际大厦10楼</p>
</td>
</tr>
<tr>
<td>
<p>广州汇兆瀛河</p>
</td>
<td>
<p>广州市荔湾区人民中路555号美国银行中心2212室</p>
</td>
</tr>
<tr>
<td>
<p>成都盛源</p>
</td>
<td>
<p>成都市青羊区西玉龙街2号新世纪广场17楼</p>
</td>
</tr>
<tr>
<td>
<p>长沙长融</p>
</td>
<td>
<p>长沙市芙蓉区佳兆业广场2211</p>
</td>
</tr>
<tr>
<td>
<p>西安洪鑫</p>
</td>
<td>
<p>陕西省西安市碑林区和平路12号和平银座308室</p>
</td>
</tr>
<tr>
<td>
<p>重庆盛融</p>
</td>
<td>
<p>重庆市两江新区天宫殿街道新南路166号2幢1单元19-6</p>
</td>
</tr>
<tr>
<td>
<p>佛山汇兆瀛河</p>
</td>
<td>
<p>广州市荔湾区人民中路555号美国银行中心2212室</p>
</td>
</tr>
<tr>
<td>
<p>武汉联信</p>
</td>
<td>
<p>武汉市武昌区友谊大道371号V+合伙人大厦塔楼7层B702</p>
</td>
</tr>
<tr>
<td>
<p>宁波格子</p>
</td>
<td>
<p>宁波市鄞州区银晨国际商务中心1幢603-605</p>
</td>
</tr>
<tr>
<td>
<p>温州格子</p>
</td>
<td>
<p>浙江省温州市鹿城区温州大道铁道大厦901</p>
</td>
</tr>
<tr>
<td>
<p>扬州汇信</p>
</td>
<td>
<p>江苏省扬州市邗江区星耀天地2号楼1504</p>
</td>
</tr>
<tr>
<td>
<p>青岛中诚</p>
</td>
<td>
<p>青岛市市北区卓越世纪中心1号楼2205</p>
</td>
</tr>
<tr>
<td>
<p>苏州敦德</p>
</td>
<td>
<p>苏州工业园区时代广场23幢B座807室</p>
</td>
</tr>
<tr>
<td>
<p>广州银沃</p>
</td>
<td>
<p>广州市天河区黄埔大道西76号1707房</p>
</td>
</tr>
<tr>
<td>
<p>南京中南</p>
</td>
<td>
<p>江苏省南京市鼓楼区汉中路2号世界贸易中心1561-1564</p>
</td>
</tr>
<tr>
<td>
<p>杭州鸿霖</p>
</td>
<td>
<p>杭州市上城区钱江路1366号华润大厦B座47楼</p>
</td>
</tr>
<tr>
<td>
<p>天津融动力</p>
</td>
<td>
<p>天津南开区广开街道南丰路宝利国际广场1号楼1门804</p>
</td>
</tr>
<tr>
<td>
<p>泛华</p>
</td>
<td>
<p>广州市天河区珠江东路16号高德置地冬广场G座4503</p>
</td>
</tr>
</tbody>
</table>
      <br />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},

  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.container {
  width: 1180px;
  margin: 30px auto 50px;
  font-size: 16px;
  line-height: 2;
}
    
    p {
        border: none;
        margin: 0;
        padding: 0;
        text-align: left;
    }
    
    .center {
        text-align: center;
    }
    
    pre {
        font-size: 3.2vw;
        transform: scale(0.8);
        transform-origin: 0;
    }
    
    ol {
        padding: 4.53vw;
        margin: 0;
    }
    
    h1,
    h2,
    h3 {
        width: 100%;
        margin-top: 1vw;
        margin-bottom: 1.2vw;
    }
    
    h1 {
        font-weight: bold;
    }
    
    h2 {
        font-weight: 400;
    }
    
    /*表格样式*/
    table {
        width: 100%;
        background: #ccc;
        margin: 10px auto;
        border-collapse: collapse;
        table-layout: fixed;
    }
    
    th,
    td {
        /* width: 26.67vw; */
        text-align: center;
        border: 1px solid #ccc;
        word-break: break-word;
    }
    
    th {
        background: #eee;
        font-weight: 700;
    }
    
    tr {
        background: #fff;
    }
</style>
