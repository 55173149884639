/* eslint-disable */
import { getStorage } from "@/util/common";

const POINT = (key, param) => {
  try {
    SA[key](param);
  } catch (e) {
    console.log(e);
  }
};
export default POINT;

export const registerTracker = () => {
  const duid = getStorage("LOCAL", "xinyeBrowserId");
  sa.registerPage({
    product: "other",
    product_h5: "super_h5",
  });
  if (!duid) {
    window.PPDMonitor.handleFingerprint().then((res) => {
      sa.registerPage({
        duid: res,
      });
    });
  } else {
    sa.registerPage({
      duid,
    });
  }
};

export const registerMonitor = () => {
  window.PPDMonitor.registerParams({
    // openid,
  });
};

export const registerPoint = () => {
  registerMonitor();
  registerTracker();
};
