import { sendReport } from "@/util/common";
import axios from "axios";

export const initAxios = ({
  toastFunc = () => {},
  loginFunc = () => {},
  addLoading = () => {},
  removeLoading = () => {},
}) => {
  const service = axios.create({
    baseURL: `${location.origin}/api`,
    withCredentials: true,
    timeout: 30000,
    // headers: {
    //     'X-PPD-TOKEN': getCookie('ppdaiAdminCasToken'),
    //     'X-PPD-APPID': '1000004270',
    //     'X-PPD-TOKENTYPE':'CAS'
    // }
  });

  service.interceptors.request.use((config) => {
    let { showLoading, url } = config;
    showLoading && addLoading(url);
    return config;
  });

  service.interceptors.response.use(
    (response) => {
      let { status, config } = response;
      let { showToast, url } = config;
      removeLoading(url);
      if (response.data?.Result === 0 || response.data?.Result === 200) {
        return Promise.resolve(response.data?.Content);
      } else {
        sendReport(response);
        if (
          status === 400 ||
          status === 401 ||
          status === 301 ||
          status === 302
        ) {
          loginFunc();
        } else {
          let msg =
            (response.data && response.data.ResultMessage) || "网络请求异常";
          showToast !== false && toastFunc(msg);
          return Promise.reject({ ...response.data, ...{ message: msg } });
        }
      }
    },
    (error) => {
      let { config } = error;
      let { showToast, url } = config;
      removeLoading(url);
      let status = error && error.response && error.response.status;
      if (status || error.message.includes("timeout")) {
        sendReport(error.response);
      }
      if (
        status === 400 ||
        status === 401 ||
        status === 301 ||
        status === 302
      ) {
        loginFunc();
      } else {
        let msg =
          (error.response &&
            error.response.data &&
            error.response.data.ResultMessage) ||
          error.message ||
          "网络请求异常";
        showToast !== false && toastFunc(msg);
        return Promise.reject(error);
      }
    }
  );

  return service;
};
