<template>
  <div class="sealand">
    <Header
      :activeSection="activeSection"
      :class="['header']"
    />
    <div class="banner-wrap">
      <div id="carousel" class="carousel-section" ref="carousel">
        <a-carousel autoplay>
          <div class="carousel-item banner-1">
            <div class="title"></div>
            <div class="desc">全链路客户管理，提升销售与管理效率</div>
            <div class="btn partner" @click="handleApply('/apply-merchant/')">
              <div class="btn-left">
                <div class="btn-num">01</div>
                <div class="btn-center">
                  <div class="btn-center-line1">我是合作商</div>
                  <div class="btn-center-line2">立即合作</div>
                </div>
              </div>
              <div class="btn-right"></div>
            </div>
            <div class="btn" @click="handleApply('/apply/')">
              <div class="btn-left">
                <div class="btn-num">02</div>
                <div class="btn-center">
                  <div class="btn-center-line1">我是客户</div>
                  <div class="btn-center-line2">立即申请</div>
                </div>
              </div>
              <div class="btn-right"></div>
            </div>
          </div>
        </a-carousel>
      </div>
    </div>
    <div class="marketing"></div>
    <div class="cooperate">
      <div class="cooperate-content"></div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@main/components/common/footer";
import Header from "@main/components/common/header";
import scrollMixin from "@main/mixin/scroll";
import { pointCommonParam } from "@main/util/util";

export default {
  components: { Header, Footer },
  mixins: [scrollMixin],
  data() {
    return {
      isSticky: false,
      activeSection: "sealand", // 用于跟踪当前活动的锚点
    };
  },
  created() {
    this.elementImp();
  },
  mounted() {},
  methods: {
    handleApply(path) {
      const label = path === "/apply-merchant/" ? "我是合作商" : "我是客户";
      let routeUrl = this.$router.resolve({
        path: `${path}`,
      });
      this.$POINT("h5_clk", {
        ...pointCommonParam,
        page: "super-海陆系统介绍页",
        tgt_event_id: "super_homepage_stay",
        tgt_name: `super-留资-${label}`,
        param1: routeUrl.href,
      });
      this.$router.push(`${path}`);
    },
    elementImp() {
      this.$POINT("h5_element_imp", {
        ...pointCommonParam,
        page: "super-海陆系统介绍页",
        tgt_event_id: "super_bigad_banner",
        tgt_name: `super-首屏banner`,
      });
      this.$POINT("h5_element_imp", {
        ...pointCommonParam,
        page: "super-海陆系统介绍页",
        tgt_event_id: "super_cooperate",
        tgt_name: `super-合作流程`,
      });
      this.$POINT("h5_element_imp", {
        ...pointCommonParam,
        page: "super-海陆系统介绍页",
        tgt_event_id: "super_marketing",
        tgt_name: `super-系统介绍`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.sealand {
  .header {
    background: #f5f9fa;
  }
  .scrolled {
    &.sticky {
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      z-index: 1;
    }
  }
  .banner-wrap {
    background: @main-bg;
    padding-bottom: 38px;
    .carousel-section {
      .setFlexPos(row, center, center);
      /deep/ .ant-carousel {
        width: @section-width;
        min-width: @section-width;
        overflow: hidden;
      }
      .carousel-item {
        border-radius: 20px;
        opacity: 1;
        .setSize(@section-width, 559px);
      }
      .banner-1 {
        padding: 60px;
        .setSize(@section-width, 559px);
        .setBgImg("@main/assets/img/hly_banner_1@2x.png", center, center, contain, no-repeat);
        .title {
          .setSize(447px, 46.5px);
          .setBgImg("@main/assets/img/hly_banner_title@2x.png", center, center, contain, no-repeat);
        }
        .desc {
          font-size: 20px;
          font-weight: 300;
          line-height: 20px;
          letter-spacing: 0em;
          color: #4e5d60;
          margin-top: 30px;
        }
        .btn {
          width: 240px;
          height: 70px;
          border-radius: 20px;
          opacity: 1;
          background: rgba(255, 255, 255, 0.6);
          box-sizing: border-box;
          border-image: linear-gradient(
              180deg,
              #ffffff 0%,
              rgba(54, 255, 235, 0) 100%
            )
            1;
          backdrop-filter: blur(8px);
          color: #4d91bc;
          .setFlexPos(row, space-between, center);
          padding: 0 20px;
          cursor: pointer;
          &-left {
            .setFlexPos(row, flex-start, center);
          }
          &-num {
            font-family: "STSongti-SC-Light";
            font-size: 40px;
            margin-right: 16px;
          }
          .btn-center {
            .setFlexPos(column, center, flex-start);
            .btn-center-line1 {
              font-size: 18px;
              font-weight: 500;
            }
            .btn-center-line2 {
              font-size: 14px;
              font-weight: 350;
            }
          }

          &:hover {
            background: linear-gradient(291deg, #37a4ff 61%, #2dcfff 116%);
            box-sizing: border-box;
            border-image: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0.5) 2%,
              rgba(54, 255, 235, 0) 100%
            );
            color: #ffffff;
            & .btn-right {
              .setBgImg("@main/assets/img/icon_arrow_r@2x.png", center, center, contain, no-repeat);
            }
          }
        }
        .partner {
          margin-top: 92px;
          margin-bottom: 30px;
        }
        .btn-right {
          .setSize(22px, 22px);
          .setBgImg("@main/assets/img/icon_arrow_r_blue@2x.png", center, center, contain, no-repeat);
        }
      }
    }
  }

  .section {
    min-height: 480px;
    background-color: @main-bg;
    margin: 20px 0;
  }
  .marketing {
    margin: 5px auto 0;
    .setSize(@section-width, 661px);
    .setBgImg("@main/assets/img/marketing@2x.png", center, center, contain, no-repeat);
  }
  .cooperate {
    margin: 50px auto 30px;
    padding-bottom: 50px;
    background: #f5f9fa;
    &-content {
      margin: 0 auto;
      .setSize(@section-width, 463px);
      .setBgImg("@main/assets/img/cooperte@2x.png", center, center, contain, no-repeat);
    }
  }
}
</style>
