import "@/assets/less/common.less";
import POINT, { registerPoint } from "@/util/point";
import { registerComponents } from "@main/util/register-components";
import "ant-design-vue/dist/antd.css";
import "@main/assets/style/andtvue.less";
import Vue from "vue";
import App from "./App.vue";
import { router } from "./router";

registerComponents(Vue);
registerPoint();
Vue.config.productionTip = false;
Vue.prototype.$POINT = POINT;
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
