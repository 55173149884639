<template>
  <header ref="header" id="header" class="sticky">
    <div class="header-content">
      <div class="logo"></div>
      <nav>
        <a
          href="#carousel"
          class="nav-item"
          :class="{ active: activeSection === 'carousel' }"
          @click.prevent="scrollTo('#carousel')"
          >首页</a
        >
        <a
          href="#aboutUs"
          class="nav-item"
          :class="{ active: activeSection === 'aboutUs' }"
          @click.prevent="scrollTo('#aboutUs')"
          >关于我们</a
        >
        <a
          href="#businessHome"
          class="nav-item"
          :class="{ active: activeSection === 'businessHome' }"
          @click.prevent="scrollTo('#businessHome')"
          >生态流程</a
        >
        <a
          class="nav-item"
          :class="{ active: activeSection === 'sealand' }"
          @click.prevent="handleSealand"
          >海陆云系统</a
        >
      </nav>
    </div>
  </header>
</template>
<script>
import { getQueryString } from "@/util/util";
import { navList, pageLists, pointCommonParam } from "@main/util/util";

export default {
  props: {
    activeSection: {
      type: String,
      default: "home",
    },
  },
  data() {
    return {};
  },
  mounted() {
    const id = getQueryString("id");
    this.$nextTick(() => {
      this.$emit("scrollTo", id);
    });
  },
  methods: {
    handleSealand(route) {
      this.$router.push("/sealand/");
    },
    scrollTo(id) {
      this.clk(id);
      const isHome = this.$route.path.includes("home");
      if (isHome) {
        this.$emit("scrollTo", id);
      } else {
        this.$router.push({
          path: "/home/",
          query: {
            id,
          },
        });
      }
    },
    clk(type) {
      const currentPage = pageLists.find(
        (item) => item.name === this.$route.name
      );
      const currentNav = navList.find((item) => type.includes(item.name));
      if (currentPage && currentNav) {
        this.$POINT("h5_clk", {
          ...pointCommonParam,
          page: `super-${currentPage.label}`,
          tgt_event_id: "super_menu",
          tgt_name: `super-首页导航-${currentNav.label}`,
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
header {
  .setFlexPos(row, center, center);
  padding: 50px 0 30px;
  transition: all 0.3s;
  background: transparent;
  .header-content {
    .setFlexPos(row, space-between, center);
    width: @section-width;
    min-width: @section-width;
    font-size: 14px;
    line-height: 16px;
    color: #3b4850;
  }
  &.sticky {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .logo {
    .setSize(100px, 31px);
    .setBgImg("@main/assets/img/pzg_logo@2x.png", center, center, contain, no-repeat);
  }

  nav {
    display: flex;
    gap: 20px;

    .nav-item {
      text-decoration: none;
      position: relative;
      padding-bottom: 5px;
      font-family: "Source Han Sans CN";
      font-size: 14px;
      font-weight: normal;
      color: @noraml-color;

      &.active {
        font-weight: 500;
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 2px;
          background-color: @active-color;
        }
      }
    }
  }
}
</style>
