import { getCookie } from '@/util/cookie';


export const setStorage = (type, key, val) => {
	const storage = type === 'SESSION' ? window.sessionStorage : window.localStorage;
	if (typeof val === 'string') {
		storage.setItem(key, val);
	} else {
		storage.setItem(key, JSON.stringify(val));
	}
}

export const getStorage = (type, key) => {
	const storage = type === 'SESSION' ? window.sessionStorage : window.localStorage;
	let value = storage.getItem(key);

	if (typeof value === 'undefined' || value === '' || value === null) {
		return value;
	}
	try {
		value = JSON.parse(value);
	} catch (e) {
        console.log(e)
    }
	return value;
}

export const uuid = () => {
	let s = [];
	let hexDigits = '0123456789abcdef';
	for (let i = 0; i < 36; i++) {
		s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
	}
	s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
	s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
	s[8] = s[13] = s[18] = s[23] = '-';

	let uuid = s.join('');
	return uuid;
}

export const sendReport = (response = {}) => {
	const { config, data, request } = response;
	const errorMessage = (data && (data.message || data.Message || data.ResultMessage)) || request.statusText; 
	const params = {
		eventId: uuid(),
		eventType: '',
		errorMessage,
		method: config.method,
		openid: getCookie('openId'),
		requestURL: request.responseURL,
		errorCode: response.status,
		duid: getStorage('LOCAL', 'xinyeBrowserId'),
	};
	// 如果监控报错不处理
	if (request.responseURL.indexOf('webuimonitor') > -1) {
		return;
	}
	// 如果是未登录(h5网关)跳转Ac
	if (params.errorMessage.indexOf('令牌') > -1 || params.errorCode === 400) {
		params.eventType = 'h5_token_error';
	} else {
		// 其余异常上报接口
		params.eventType = 'h5_ajax_error';
	}
	try {
		window?.PPDMonitor?.sendReport(params);
	} catch (e) {
		console.log(e);
	}
	
}