<template>
  <div class="home">
    <Header
      ref="mainHeader"
      :activeSection="activeSection"
      @scrollTo="scrollTo"
      :class="['header', isScrolled ? 'scrolled' : '']"
    />
    <div class="banner-wrap">
      <div id="carousel" class="carousel-section" ref="carousel">
        <a-carousel autoplay :autoplaySpeed="3000" :afterChange="afterChange">
          <div class="carousel-item banner-1">
            <div class="title"></div>
            <div class="desc">为中小微企业提供一站式融资服务</div>
            <div class="btn partner" @click="handleApply('/apply-merchant/')">
              <div class="btn-left">
                <div class="btn-num">01</div>
                <div class="btn-center">
                  <div class="btn-center-line1">我是合作商</div>
                  <div class="btn-center-line2">立即合作</div>
                </div>
              </div>
              <div class="btn-right"></div>
            </div>
            <div class="btn" @click="handleApply('/apply/')">
              <div class="btn-left">
                <div class="btn-num">02</div>
                <div class="btn-center">
                  <div class="btn-center-line1">我是客户</div>
                  <div class="btn-center-line2">立即申请</div>
                </div>
              </div>
              <div class="btn-right"></div>
            </div>
          </div>
          <div class="carousel-item banner-2"></div>
          <div class="carousel-item banner-3"></div>
        </a-carousel>
      </div>
      <div class="feature">
        <div class="item" v-for="(item, index) of featureList" :key="index">
          <div class="name">{{ item.name }}</div>
          <div class="name-en">{{ item.nameEn }}</div>
        </div>
      </div>
    </div>
    <div id="aboutUs" class="about-us" ref="aboutUs">
      <div class="title">助力小微融资，累计服务客户{{ userNumber }}</div>
      <div class="summary">
        <div class="summary-title">拍掌柜简介</div>
        <div class="summary-content">
          <div class="p">
            拍掌柜是聚焦于房抵业务的科技品牌，依托集获客、营销、销售管理、产品推荐等功能于一身的「海陆云」系统，利用多年积累的数字化精准获客能力，与线下优质门店合作，共同为中小微企业客户提供一站式融资服务。
          </div>
          <div class="p">
            通过和各大产业平台深度合作，精准获取优质商机，同时联合金融机构及线下优质服务商，提供全流程的融资服务。累计已为超过10万小微客户提供服务。
            项目自2021年底上线以来，已与北京、上海、广州、深圳、天津、杭州等全国30多个城市，100多家门店展开深度合作。
          </div>
        </div>
        <div class="summary-footer">
          <div class="item">
            <div>服务客户</div>
            <div class="num">{{ userNumber }}</div>
          </div>
          <div class="item" v-if="!!dealAmount">
            <div>放款额</div>
            <div class="num">{{ dealAmount }}</div>
          </div>
        </div>
      </div>
    </div>
    <div id="businessHome" class="business" ref="businessHome"></div>
    <Footer />
  </div>
</template>

<script>
import { formatPrice, replaceLastFourDigitsWithZero } from "@/util/util";
import { queryStatisticsRecordList } from "@main/api/service";
import Footer from "@main/components/common/footer";
import Header from "@main/components/common/header";
import { pointCommonParam } from "@main/util/util";
export default {
  components: { Footer, Header },
  data() {
    return {
      isSticky: false,
      isScrolled: false,
      activeSection: "carousel", // 用于跟踪当前活动的锚点
      pageInfo: {},
      featureList: [
        {
          name: "科技驱动",
          nameEn: "Technology Driven",
        },
        {
          name: "精准商机",
          nameEn: "Business Opportunities",
        },
        {
          name: "上市平台",
          nameEn: "Listing Platform",
        },
        {
          name: "合规经营",
          nameEn: "Compliant Operation",
        },
      ],
    };
  },
  computed: {
    userNumber() {
      let _userNumber = this.pageInfo.userNumber;
      if (_userNumber) {
        _userNumber = formatPrice(_userNumber);
      } else {
        _userNumber = "300,000+";
      }
      return _userNumber;
    },
    dealAmount() {
      let _dealAmount = this.pageInfo.dealAmount;
      if (_dealAmount) {
        _dealAmount = formatPrice(replaceLastFourDigitsWithZero(_dealAmount));
      }
      return _dealAmount;
    },
  },
  created() {
    this.queryStatisticsRecordList();
    this.elementImp();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    async queryStatisticsRecordList() {
      const res = await queryStatisticsRecordList();
      this.pageInfo = res || {};
    },
    handleScroll() {
      if (this.$refs.mainHeader && this.$refs.mainHeader.$refs.header) {
        const { mainHeader } = this.$refs;
        const headerHeight = mainHeader.$refs.header.offsetHeight;
        this.isSticky = window.scrollY > headerHeight;

        // 根据滚动位置更新activeSection
        const { scrollY } = window;
        const sectionOffsets = {
          "#carousel": this.$refs.carousel.offsetTop,
          "#aboutUs": this.$refs.aboutUs.offsetTop,
          "#businessHome": this.$refs.businessHome.offsetTop,
        };
        for (const section in sectionOffsets) {
          if (scrollY >= sectionOffsets[section] - 111) {
            this.activeSection = section.replace("#", "");
          }
        }
        this.isScrolled = scrollY > 0;
      }
    },
    scrollTo(selector) {
      const target = document.querySelector(selector);
      if (target) {
        window.scrollTo({
          top: target.offsetTop - 111,
          behavior: "smooth",
        });
      }
    },
    handleSealand() {
      this.$router.push("/sealand/");
    },
    handleApply(path) {
      const label = path === "/apply-merchant/" ? "我是合作商" : "我是客户";
      let routeUrl = this.$router.resolve({
        path: `${path}`,
      });
      const param1 = routeUrl.href;
      this.$POINT("h5_clk", {
        ...pointCommonParam,
        page: "super-官网首页",
        tgt_event_id: "super_homepage_stay",
        tgt_name: `super-留资-${label}`,
        param1,
      });
      this.$router.push(`${path}`);
    },
    afterChange(current) {
      this.$POINT("h5_element_imp", {
        ...pointCommonParam,
        page: "super-官网首页",
        tgt_event_id: "super_homepage_banner",
        tgt_name: `super-首页轮播-${current}`,
      });
    },
    elementImp() {
      this.$POINT("h5_element_imp", {
        ...pointCommonParam,
        page: "super-官网首页",
        tgt_event_id: "super_homepage_service",
        tgt_name: `super-首页-业务生态`,
      });
      this.$POINT("h5_element_imp", {
        ...pointCommonParam,
        page: "super-官网首页",
        tgt_event_id: "super_homepage_aboutus",
        tgt_name: `super-首页-关于我们`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  .header {
    background: #f5f9fa;
  }
  .scrolled {
    &.sticky {
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      z-index: 1;
    }
  }
  .banner-wrap {
    background: @main-bg;
    padding-bottom: 38px;
    .carousel-section {
      .setFlexPos(row, center, center);
      border-radius: 20px;
      /deep/ .ant-carousel {
        width: @section-width;
        min-width: @section-width;
        overflow: hidden;
        border-radius: 20px;
      }
      .carousel-item {
        border-radius: 20px;
        opacity: 1;
        .setSize(@section-width, 482px);
      }
      .banner-1 {
        padding: 60px;
        .setSize(@section-width, 482px);
        .setBgImg("@main/assets/img/banner_1@2x.png", center, center, contain, no-repeat);
        .title {
          .setSize(513px, 50px);
          .setBgImg("@main/assets/img/banner_title@2x.png", center, center, contain, no-repeat);
        }
        .desc {
          font-size: 20px;
          font-weight: 300;
          line-height: 20px;
          letter-spacing: 0em;
          color: #ffffff;
          margin-top: 30px;
        }
        .btn {
          width: 240px;
          height: 70px;
          border-radius: 20px;
          opacity: 1;
          background: rgba(245, 249, 250, 0.2);
          backdrop-filter: blur(8px);
          color: #ffffff;
          .setFlexPos(row, space-between, center);
          padding: 0 20px;
          cursor: pointer;
          &-left {
            .setFlexPos(row, flex-start, center);
          }
          &-num {
            font-family: "STSongti-SC-Light";
            font-size: 40px;
            margin-right: 16px;
          }
          .btn-center {
            .setFlexPos(column, center, flex-start);
            .btn-center-line1 {
              font-size: 18px;
              font-weight: 500;
            }
            .btn-center-line2 {
              font-size: 14px;
              font-weight: 350;
            }
          }

          &:hover {
            background: #f5f9fa;
            color: #3b4850;
            & .btn-num {
              color: #abc5db;
            }
            & .btn-right {
              .setBgImg("@main/assets/img/icon_arrow_r_2@2x.png", center, center, contain, no-repeat);
            }
          }
        }
        .partner {
          margin-top: 92px;
          margin-bottom: 30px;
        }
        .btn-right {
          .setSize(22px, 22px);
          .setBgImg("@main/assets/img/icon_arrow_r@2x.png", center, center, contain, no-repeat);
        }
      }
      .banner-2 {
        .setSize(@section-width, 482px);
        .setBgImg("@main/assets/img/banner_2@3x.png", center, center, contain, no-repeat);
      }
      .banner-3 {
        .setSize(@section-width, 482px);
        .setBgImg("@main/assets/img/banner_3@2x.png", center, center, contain, no-repeat);
      }
    }
    .feature {
      width: 800px;
      .setFlexPos(row, flex-start, center);
      margin: 38px auto 0;
      .item {
        min-width: 200px;
        text-align: center;
        font-family: "Source Han Sans CN";
        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: -2px;
          .setSize(1px, 50px);
          .setBgImg("@main/assets/img/icon_line@2x.png", center, center, contain, no-repeat);
        }
        &:last-child {
          &::after {
            content: "";
            position: absolute;
            right: 0;
            top: -2px;
            .setSize(1px, 50px);
            .setBgImg("@main/assets/img/icon_line@2x.png", center, center, contain, no-repeat);
          }
        }
        .name {
          font-size: 16px;
          color: #3b4850;
        }
        .name-en {
          font-size: 11px;
          font-weight: 300;
          color: #acc5db;
        }
        .line {
          .setSize(1px, 50px);
          .setBgImg("@main/assets/img/icon_line@2x.png", center, center, contain, no-repeat);
        }
      }
    }
  }

  .about-us {
    margin: 22px auto;
    padding: 77px 100px;
    color: #3b4850;
    font-family: "Source Han Sans CN";

    .setSize(@section-width, 648px);
    .setBgImg("@main/assets/img/about_us@2x.png", center, center, contain, no-repeat);
    .title {
      font-size: 40px;
      font-weight: 500;
      line-height: 40px;
      letter-spacing: 0em;
    }
    .summary {
      margin-top: 100px;
      &-title {
        font-size: 30px;
      }
      &-content {
        width: 528px;
        font-size: 13px;
        font-weight: 350;
        line-height: 25px;
        text-align: justify;
        margin-top: 30px;
      }
      &-footer {
        margin-top: 42px;
        font-size: 15px;
        .setFlexPos(row, flex-start, center);
        gap: 61px;
        .item {
          position: relative;
          min-width: 150px;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0px;
            .setSize(100%, 30px);
            min-width: 150px;
            .setBgImg("@main/assets/img/lines_bg@2x.png", center, center, contain, repeat);
          }
          .num {
            font-size: 30px;
            font-weight: 250;
            line-height: 30px;
            margin-top: 8px;
            font-family: "Source Han Sans CN";
          }
        }
      }
    }
  }
  .business {
    margin: 70px auto 30px;

    .setSize(@section-width, 648px);
    .setBgImg("@main/assets/img/business@2x.png", center, center, contain, no-repeat);
  }
}
</style>
