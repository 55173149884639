<template>
  <div class="landing">
    <div class="form-wrap">
      <div class="form-wrap-main">
        <div class="left">
          <div class="logo"></div>
          <div class="fcdy"></div>
          <div class="bg-line"></div>
          <div class="yhqd"></div>
          <div class="qyls"></div>
          <div class="gg"></div>
        </div>
        <div class="right">
          <template v-if="showForm">
            <ApplyForm
              class="landing-form"
              pageFrom="landing"
              @onSuccess="applySuccess"
            />
          </template>
          <template v-else>
            <template v-if="showSuccessBlock">
              <div class="success" v-if="isSuccess">
                <div class="des1">您好！您的申请已收到</div>
                <div class="des2">我们将在7个工作日内与您联系！</div>
              </div>
              <div class="success" v-else>
                <div class="des1">您好，该区域暂未合作门店。</div>
                <div class="des3">
                  后续城市开通合作门店后，将第一时间与您联系！
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>

      <div class="form-wrap-footer">
        <div
          v-for="(item, index) of lists"
          :key="index"
          class="item"
          :style="{
            background: `url(${item.src}) center center no-repeat`,
            backgroundSize: 'contain',
          }"
        ></div>
      </div>
    </div>
    <div class="marketing"></div>
    <div class="cooperate"></div>
  </div>
</template>

<script>
import { getQueryString } from "@/util/util";
import ApplyForm from "@main/components/apply/applyForm";
import { pointCommonParam } from "@main/util/util";
import { getOrderStatusV3 } from "@main/api/service";

export default {
  components: { ApplyForm },
  data() {
    return {
      isSticky: false,
      showForm: true,
      clueId: "",
      showSuccessBlock: false,
      isSuccess: true,
      lists: [
        {
          src: require("@main/assets/img/landing_edg@3x.png"),
        },
        {
          src: require("@main/assets/img/landing_fkk@3x.png"),
        },
        {
          src: require("@main/assets/img/landing_fwh@3x.png"),
        },
      ],
    };
  },
  created() {
    this.elementImp();
    this.submitSource = getQueryString("submitSource") || "PPDLoan";
  },
  mounted() {},
  methods: {
    applySuccess(data) {
      this.showForm = false;
      this.clueId = data.clueId;
      this.getOrderStatus();
      this.$POINT("h5_element_imp", {
        ...pointCommonParam,
        page: "super-PC版投放结果提交页",
        tgt_event_id: "super_submitresult",
        tgt_name: `super-提交结果`,
        param: this.submitSource,
      });
    },
    elementImp() {
      this.$POINT("h5_element_imp", {
        ...pointCommonParam,
        page: "super-PC端投放页信息填写页",
        tgt_event_id: "super_step",
        tgt_name: `super-填写信息`,
      });
    },
    async getOrderStatus() {
      try {
        const res = await getOrderStatusV3({
          clueId: this.clueId,
        });
        this.showSuccessBlock = true;
        if (res.status === 300) {
          // 无效线索
          this.isSuccess = false;
        }
      } catch (e) {
        this.showSuccessBlock = true;
        this.isSuccess = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.landing {
  padding-bottom: 81px;
  .form-wrap {
    .setSize(100%, 893px);
    .setBgImg("@main/assets/img/landing_bg@3x.png", center, center, cover, no-repeat);

    .form-wrap-main {
      display: flex;
      justify-content: center;
      .left {
        padding-top: 178px;
        .logo {
          .setSize(138px, 43px);
          .setBgImg("@main/assets/img/landing_logo@3x.png", center, center, contain, no-repeat);
        }
        .fcdy {
          .setSize(388px, 59px);
          .setBgImg("@main/assets/img/landing_fcdy@3x.png", center, center, contain, no-repeat);
          margin-top: 16px;
        }
        .bg-line {
          .setSize(40px, 4px);
          .setBgImg("@main/assets/img/landing_bg_line@2x.png", center, center, contain, no-repeat);
          margin-top: 34px;
          margin-left: 9.2px;
        }
        .yhqd {
          .setSize(199px, 30px);
          .setBgImg("@main/assets/img/landing_yhqd@3x.png", center, center, contain, no-repeat);
          margin-top: 81px;
        }
        .qyls {
          .setSize(405px, 30px);
          .setBgImg("@main/assets/img/landing_qyls@3x.png", center, center, contain, no-repeat);
          margin-top: 14px;
        }
        .gg {
          .setSize(288px, 27px);
          .setBgImg("@main/assets/img/landing_gg@3x.png", center, center, contain, no-repeat);
          margin-top: 41px;
        }
      }
      .right {
        padding-top: 152px;
        padding-left: 136px;
        width: 342px;
        .landing-form {
          .setSize(342px, 416px);
          .setBgImg("@main/assets/img/landing_form_bg@3x.png", center, center, contain, no-repeat);
          /deep/ .ant-form {
            padding-top: 48px;
          }
        }
        .success {
          .setSize(342px, 416px);
          .setBgImg("@main/assets/img/landing_success@3x.png", center, center, contain, no-repeat);
          font-size: 15px;
          text-align: center;
          color: #49596c;
          .des1 {
            padding: 290px 0 8px;
          }
          .des3 {
            padding: 0 40px;
          }
        }
      }
    }
    .form-wrap-footer {
      display: flex;
      justify-content: center;
      margin-top: 120px;
      .item {
        width: 270px;
        height: 120px;
        margin-left: 48px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .section {
    min-height: 480px;
    background-color: @main-bg;
    margin: 20px 0;
  }
  .marketing {
    margin: 20px auto 0;
    .setSize(@section-width, 366px);
    .setBgImg("@main/assets/img/landing_lc@3x.png", center, center, contain, no-repeat);
  }
  .cooperate {
    margin: 20px auto;
    .setSize(@section-width, 604px);
    .setBgImg("@main/assets/img/landing_cjwt@3x.png", center, center, contain, no-repeat);
  }
}
</style>
