<template>
  <div class="card">
    <div class="left">
      <div class="name">{{ card.name }}</div>
      <div class="item address">
        <div class="label">门店地址：</div>
        <div>{{ card.address }}</div>
      </div>
      <div class="item">
        <div class="label">官方客服：</div>
        <div>{{ card.phone }}</div>
      </div>
      <div class="item">
        <div class="label">营业时间：</div>
        <div>{{ card.businessHours }}</div>
      </div>
    </div>
    <div class="right">
      <div class="pic" :style="{backgroundImage: `url(${card.pic})`, backgroundSize: 'cover'}"></div>
    </div>
  </div>
</template>

<script>
import ApplyForm from "@main/components/apply/applyForm";
import Header from "@main/components/common/header";
import {pointCommonParam} from "@main/util/util";

export default {
  props: {
    card: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="less" scoped>
.card {
  width: 1180px;
  height: 337px;
  border-radius: 8px;
  background: #f5f9fa;
  margin: 30px auto;
  display: flex;
  justify-content: space-between;
  padding: 30px;
  .left {
    padding: 20px;
    .name {
      font-family: Source Han Sans CN;
      font-size: 34px;
      font-weight: bold;
      color: #29343B;
    }
    .item {
      display: flex;
      align-items: center;
      font-family: Source Han Sans CN;
      font-size: 15px;
      font-weight: normal;
      margin-bottom: 10px;
      color: #899196;
      .label {
        color: #424D53;
      }
    }
    .address {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 50px;
      margin-bottom: 20px;
    }
  }
  .right {
    position: relative;
    .pic {
      width: 682px;
      height: 277px;
      border-radius: 2px;
      opacity: 1;
    }
  }
}
</style>
