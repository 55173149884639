<template>
  <div class="apply-result">
    <Header :activeSection="activeSection" />

    <template v-if="showSuccessBlock">
      <div class="success" v-if="isSuccess">
        <div class="des1">{{ tip }}</div>
        <div class="des2">我们将在7个工作日内与您联系！</div>
      </div>
      <div class="success" v-else>
        <div class="des1">您好，该区域暂未合作门店。</div>
        <div class="des2">后续城市开通合作门店后，将第一时间与您联系！</div>
      </div>
    </template>
  </div>
</template>

<script>
import { getCookie, getQueryString } from "@/util/util";
import { getOrderStatusV3 } from "@main/api/service";
import Header from "@main/components/common/header";
import { pointCommonParam } from "@main/util/util";

export default {
  components: { Header },
  data() {
    return {
      activeSection: "", // 用于跟踪当前活动的锚点
      clueId: "",
      showSuccessBlock: false,
      isSuccess: true,
    };
  },
  computed: {
    tip() {
      return this.pageFrom === "apply-merchant"
        ? "您好，您的合作申请已收到"
        : "您好，您的申请已收到";
    },
  },
  created() {
    this.clueId = getQueryString("clueId");
    this.pageFrom = getQueryString("pageFrom");
    this.isMerchant = this.pageFrom === "apply-merchant";
    this.elementImp();
    if (!getCookie("token")) {
      this.goBackHome();
      return;
    }
    if (this.pageFrom === "apply-merchant") {
      this.showSuccessBlock = true;
    } else {
      this.getOrderStatus();
    }
  },

  mounted() {},
  methods: {
    async getOrderStatus() {
      try {
        const res = await getOrderStatusV3({
          clueId: this.clueId,
        });
        this.showSuccessBlock = true;
        if (res.status === 300) {
          // 无效线索
          this.isSuccess = false;
        }
      } catch (e) {
        // 即将回到首页
        this.goBackHome();
      }
    },
    goBackHome() {
      this.$message.loading("未查询到线索信息，即将回到首页...");
      this.timeId = setTimeout(() => {
        this.$router.replace("/home/");
        clearTimeout(this.timeId);
      }, 3000);
    },
    elementImp() {
      this.$POINT("h5_element_imp", {
        ...pointCommonParam,
        page: "super-提交结果",
        tgt_event_id: "super_submitresult",
        tgt_name: `super-提交结果`,
        param1: this.isMerchant ? "B端门店" : "C端客户",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.apply-result {
  width: 100%;
  min-height: 100vh;
  .setBgImg("@main/assets/img/apply_bg@2x.png", center, center, cover, no-repeat);
  .success {
    .setSize(320px, 340px);
    .setBgImg("@main/assets/img/apply_success@2x.png", center, center, contain, no-repeat);
    margin: 100px auto;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    .des1 {
      padding: 234px 0 8px;
    }
  }
}
</style>
