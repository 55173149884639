import { initAxios } from "@/api/http";
import { message } from "ant-design-vue";
import { addLoading, removeLoading } from "./loading";
const service = initAxios({
  toastFunc: message.error,
  loginFunc: () => {
    // 跳转登录页
    // message.error('请登录')
  },
  addLoading,
  removeLoading,
});
export default service;
