<template>
  <div class="apply">
    <Header :activeSection="activeSection" />
    <div class="form-wrap">
      <ApplyForm />
    </div>
  </div>
</template>

<script>
import ApplyForm from "@main/components/apply/applyForm";
import Header from "@main/components/common/header";
import { pointCommonParam } from "@main/util/util";

export default {
  components: { Header, ApplyForm },
  data() {
    return {
      activeSection: "", // 用于跟踪当前活动的锚点
    };
  },
  created() {
    this.elementImp();
  },
  methods: {
    elementImp() {
      this.$POINT("h5_element_imp", {
        ...pointCommonParam,
        page: "super-信息填写页",
        tgt_event_id: "super_step",
        tgt_name: `super-填写信息`,
        param1: this.isMerchant ? "B端门店" : "C端客户",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.apply {
  width: 100%;
  min-height: 100vh;
  .setBgImg("@main/assets/img/apply_bg@2x.png", center, center, cover, no-repeat);
  /deep/header.sticky {
    z-index: 0;
  }
  .form-wrap {
    margin: 100px auto;
    display: flex;
    justify-content: center;
  }
}
</style>
