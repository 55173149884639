export const pointCommonParam = {
  param: "PC官网",
};

// 页面
export const pageLists = [
  {
    label: "官网首页",
    name: "Home",
  },
  {
    label: "海陆系统介绍页",
    name: "Sealand",
  },
  {
    label: "信息填写页",
    name: "Apply",
  },
  {
    label: "信息填写页",
    name: "ApplyMerchant",
  },
  {
    label: "提交结果页",
    name: "applyResult",
  },
];

export const navList = [
  {
    label: "首页",
    name: "carousel",
  },
  {
    label: "关于我们",
    name: "aboutUs",
  },
  {
    label: "生态流程",
    name: "businessHome",
  },
  {
    label: "海陆云系统",
    name: "sealand",
  },
];
