import { message } from 'ant-design-vue';
const createMaskElement = () => {
    const mask = document.createElement('div');
    mask.style.width = '100%';
    mask.style.height = '100%';
    mask.style.zIndex = '99999';
    mask.style.position = 'absolute';
    mask.style.top = '0';
    mask.style.left = '0';
    mask.style.background = 'transparent';
    mask.id = 'loadingMask'
    return mask
}

let loading = null;
let ajaxList= [];
export const removeLoading = (url) => {
    let index = ajaxList.indexOf(url);
    if ( index > -1) {
        ajaxList.splice(index, 1)
    }
    if (!ajaxList.length && index > -1) {
        let loadingMask = document.getElementById('loadingMask');
        loadingMask && document.body.removeChild(loadingMask);
        loading && setTimeout(loading, 0);
        loading = null;
    }
    console.log('remove ajaxCount', ajaxList)
}

export const addLoading = (url) => {
    ajaxList.push(url)
    if (!loading) {
        let loadingMask = document.getElementById('loadingMask');
        !loadingMask && document.body.appendChild(createMaskElement());
        loading = message.loading({
            content: '处理中',
            duration: 0
        })
    }
    console.log('add ajaxCount', ajaxList)
}
