<template>
  <div class="form">
    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item ref="applyName" label="" prop="applyName">
        <a-input
          v-model="form.applyName"
          placeholder="请输入您的姓名"
          size="large"
          @focus="onFocus({ name: '姓名' })"
        />
      </a-form-model-item>
      <a-form-model-item ref="city" label="" prop="city">
        <a-cascader
          v-model="form.city"
          size="large"
          :options="districts"
          placeholder="请选择您所在的城市"
          :displayRender="cityDisplayRender"
          @change="onChangeCity"
          @focus="onFocus({ name: '城市' })"
        />
      </a-form-model-item>
      <a-form-model-item class="phone" ref="phone" label="" prop="phone">
        <a-input
          v-model="form.phone"
          placeholder="请输入您的手机号"
          size="large"
          :maxLength="11"
          oninput="value=value.replace(/[^\d]/g,'')"
          id="tel_number"
          @focus="onFocus({ name: '手机号' })"
        />
        <div class="send-code" id="send_sms">获取验证码</div>
      </a-form-model-item>
      <a-form-model-item ref="code" label="" prop="code">
        <a-input
          v-model="form.code"
          placeholder="请输入验证码"
          size="large"
          id="sms_code_input"
          :maxLength="6"
          @focus="onFocus({ name: '验证码' })"
        />
      </a-form-model-item>
      <a-form-model-item>
        <div class="submit" id="submit" @click="onSubmit">
          {{ isMerchant ? '立即合作' : '立即申请' }}
        </div>
      </a-form-model-item>
    </a-form-model>
    <div class="protocol">
      <a-checkbox id="agreement" @change="changeAgreement"
      >本人已阅读并同意<span class="protocol-title" @click.prevent="handleLink('protocol')">《用户协议》</span
      ><span class="protocol-title" @click.prevent="handleLink('personal-info')"
      >《个人信息处理规则》</span
      ></a-checkbox
      >
    </div>
  </div>
</template>

<script>
import { getCityName } from '@/util/city';
import { districts } from '@/util/district';
import { getQueryString } from '@/util/util';
import { landingPageReport, loanApplyByDeliver, merchantLoanApplyByDeliver } from '@main/api/service';
import { CHANNEL_ID, SOURCE_ID_B, SOURCE_ID_C } from '@main/util/constant';
import { pointCommonParam } from '@main/util/util';

export default {
  name: 'ApplyForm',
  props: {
    pageFrom: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      other: '',
      isMerchant: true,
      formData: {},
      form: {
        applyName: '',
        city: [],
        phone: '',
        code: '',
      },
      rules: {
        applyName: [
          {
            required: true,
            message: '请输入您的姓名',
          },
        ],
        city: [
          {
            required: true,
            message: '请选择您所在的城市',
          },
        ],
        phone: [
          {
            required: true,
            message: '请输入您的手机号',
          },
          {
            pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
            message: '请输入正确手机号',
          },
        ],
        code: [
          {
            required: true,
            message: '请输入验证码',
          },
        ],
      },
      districts,
    };
  },
  created() {
    const { getRSAEncryptKey, aesEncrypt, getPublicKey } = require('@/util/encrypt');
    Object.assign(this, { getRSAEncryptKey, aesEncrypt, getPublicKey });
    this.dealWithPoints();
    this.pageReport();
    getPublicKey();
    this.init();
  },
  mounted() {
    this.createScript();
  },
  methods: {
    init() {
      this.isMerchant = this.$route.path.includes('apply-merchant');
      this.sourceId = getQueryString('sourceId') || (this.isMerchant ? SOURCE_ID_B : SOURCE_ID_C);
      this.channelAlias = getQueryString('channelAlias') || 'O2O_Chengyi';
      this.channelId = getQueryString('channelId') || CHANNEL_ID;
      this.pageType = this.$route.meta.pageType;
      this.submitSource = getQueryString('submitSource') || 'PPDLoan';
    },
    // 处理埋点参数
    dealWithPoints() {
      this.pointPage = this.pageFrom === 'landing' ? 'super-PC端投放页信息填写页' : 'super-信息填写页';
    },
    // 曝光接口
    pageReport() {
      landingPageReport({
        url: window.location.href,
      });
    },
    createScript() {
      const body = document.getElementsByTagName('head')[0];
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = '//ac.ppdaicdn.com/loginsdk/V4/loginsdk_V4.5.min.js';
      body.appendChild(script);
      script.onload = () => {
        this.sdk();
      };
    },
    sdk() {
      const param = {
        loginSource: 'H5', // 登录来源
        appId: '1000004879',
        origin: location.host,
        sourceId: this.sourceId,
        userRole: 8,
        accessType: 'loginandreg',
        extraInfo: {},
        elements: {
          mobileInput: '#tel_number',
          smsCodeInput: '#sms_code_input',
          imgCodeInput: '#img_code_input',
          sendImgCode: '#sendImgCode',
          imgCodeBox: '#imgCodeBox',
          sendSmsCode: '#send_sms',
          submit: '#submit',
          agreement: '#agreement',
          // errorInfo: '#reg_errormsg',
        },
        onerror: (errMsg) => {
          console.log('错误信息回调函数', errMsg);
          if (errMsg === '请输入手机号') {
            this.$refs.ruleForm.validateField('phone');
            return;
          }
          if (errMsg === '请输入正确的手机号') {
            return;
          }
          if (errMsg === '短信验证码不能为空') {
            return;
          }
          this.$message.error(errMsg);
        },
        afterLoginOrReg: (data, type) => {
          const text = this.isMerchant ? '立即合作' : '立即申请';
          const dom = document.getElementById('submit');
          dom && (dom.innerText = text);
          dom && dom.removeAttribute('disabled');
          /* eslint-disable */
          ppdFastLogin.reset({ isLoging: false, isRegistring: false });

          if (this.valided) {
            this.submiting();
          }
        },
        sendSmsSuccess: () => {
          const dom = document.querySelector('#send_sms');
          this.smsBgcolor = getComputedStyle(dom).getPropertyValue('background');
          const timer = setInterval(() => {
            if (!dom) {
              clearInterval(timer);
              return;
            }
            const text = dom.getAttribute('disabled');
            if (!text) {
              dom.style.background = this.smsBgcolor;
              clearInterval(timer);
              this.$emit('sendSmsFinish');
            } else {
              dom.style.background = '#9FAAB5';
            }
          }, 1000);
        },
        sendSmsFail: () => {
          console.log('发送短信失败时回调函数');
        },
      };
      window.ppdFastLogin.init(param);
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.valided = true;
          return;
        } else {
          this.valided = false;
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    submiting() {
      const api = this.isMerchant ? merchantLoanApplyByDeliver : loanApplyByDeliver;
      const { applyName, phone, houseCity, provinceId, cityId, code } = Object.assign(this.form, this.formData);
      const _formData = {
        applyName,
        phone,
        houseCity,
        provinceId,
        cityId,
        code,
      };
      this.$POINT('h5_clk', {
        ...pointCommonParam,
        page: this.pointPage,
        tgt_event_id: 'super_submit_clk',
        tgt_name: this.isMerchant ? `super-立即合作` : 'super-立即申请',
        param1: this.isMerchant ? 'B端门店' : 'C端客户',
        param2: JSON.stringify(_formData),
      });
      this.getPublicKey()
        .then((res) => {
          if (res) {
            api({
              channelAlias: this.channelAlias,
              formInfo: this.aesEncrypt(JSON.stringify(_formData)),
              encryptAESKey: this.getRSAEncryptKey(),
              sourceId: this.sourceId,
              channelId: this.channelId,
              submitSource: this.submitSource,
              url: window.location.href,
            })
              .then((res) => {
                const { data } = res;
                if (data.applyStatus === 1) {
                  if (this.pageFrom === 'landing') {
                    // 当前是landing页面
                    this.$emit('onSuccess', {
                      clueId: data.clueId,
                    });
                  } else {
                    // 当前是 apply/apply-merchant 页面
                    this.$router.replace({
                      path: '/apply-result/',
                      query: {
                        clueId: data.clueId,
                        pageFrom: this.isMerchant ? 'apply-merchant' : 'apply',
                      },
                    });
                  }
                } else {
                  this.$message.error('申请失败，请稍后再试！');
                  document.querySelector('#sms_code_input').value = '';
                }
              })
              .catch(() => {
                document.querySelector('#sms_code_input').value = '';
              });
          }
        })
        .catch((err) => {
          this.$message.error('申请失败，请稍后再试！');
        });
    },
    getUrlValue(kValue, url) {
      const reg = /([^?&=]+)=([^?&=]+)/g;
      const obj = {};
      url.replace(reg, ($, $1, $2) => {
        obj[$1] = $2;
      });
      for (let keyName in obj) {
        if (keyName === kValue) {
          return obj[keyName];
        }
      }
    },
    cityDisplayRender({ labels }) {
      const list = ['北京', '天津', '上海', '重庆'];
      if (list.includes(labels[0])) {
        return labels.join('');
      } else {
        return labels.join('省');
      }
    },
    onChangeCity(value, selectedOptions) {
      const list = ['北京', '天津', '上海', '重庆'];
      let _houseCity = '';
      if (list.includes(selectedOptions[0].label)) {
        _houseCity = getCityName(value[1]).replace('-', '');
      } else {
        _houseCity = getCityName(value[1]).replace('-', '省');
      }
      this.formData['houseCity'] = _houseCity;
      this.formData['provinceId'] = value[0];
      this.formData['cityId'] = value[1];
    },
    handleLink(path) {
      const url = path === 'protocol'
          ? 'https://agrview.ppdai.com/hailu/PAIZGPCyonghuxieyi/index.html'
          : 'https://agrview.ppdai.com/hailu/PAIZGPCgerenxinxi/index.html'
      window.open(url, '_blank');
    },
    onFocus({ name }) {
      this.$POINT('h5_clk', {
        ...pointCommonParam,
        page: this.pointPage,
        tgt_event_id: 'super_input_clk',
        tgt_name: `super-${name}聚焦`,
        param1: this.isMerchant ? 'B端门店' : 'C端客户',
      });
    },
    changeAgreement() {
      this.$POINT('h5_clk', {
        ...pointCommonParam,
        page: this.pointPage,
        tgt_event_id: 'super_register_clk',
        tgt_name: `super-用户协议`,
        param1: this.isMerchant ? 'B端门店' : 'C端客户',
      });
    },
  },
};
</script>

<style lang="less" scoped>
.form {
  .setSize(342px, 458px);
  .setBgImg('@main/assets/img/apply_form_bg@2x.png', center, center, contain, no-repeat);
  // margin: 100px auto;
  .ant-form {
    padding: 90px 36px 0;
    /deep/.ant-form-explain {
      position: relative;
      top: 2px;
    }
  }
  .phone {
    position: relative;
    .send-code {
      position: absolute;
      right: 0;
      top: -10px;
      font-size: 12px;
      font-weight: normal;
      color: #5db6ff;
      padding-right: 14px;
      right: 3px;
      top: -9px;
      height: 36px;
      line-height: 36px;
      cursor: pointer;
      z-index: 1;
      background-color: #ffffff !important;
      &.disabled {
        cursor: default;
      }
    }
  }
  .submit {
    .setSize(270px, 50px);
    border-radius: 100px;
    opacity: 1;
    background: linear-gradient(271deg, #5bb3ff 0%, #86ebff 152%);
    text-align: center;
    line-height: 50px;
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 0px 2px 4px #1b96ff;
    cursor: pointer;
  }
  .protocol {
    font-size: 12px;
    color: #3d3d3d;
    letter-spacing: 0em;
    margin: 0 auto;
    padding-left: 10px;
    .setFlexPos(row, center, center);
    .protocol-title {
      color: #5db6ff;
    }
  }
  .ant-checkbox-wrapper {
    font-size: 12px;
    color: #3d3d3d;
    /deep/.ant-checkbox-inner {
      border-radius: 5px;
    }
    /deep/.ant-checkbox-checked::after {
      border-radius: 5px;
    }
  }
}
</style>
